import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon/build/node/luxon';

import { nFormatter } from '../../../../core/utils';

import Poster from '../../../../components/Poster';
import Displays from '../../../../components/Displays';

import { Row, Col } from 'reactstrap';

import isScreen from '../../../../core/screenHelper';

class WidgetOpsisVenueCard extends React.Component {

  static propTypes = {
    venue: PropTypes.object,
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false,
  };

  constructor(props) {
    super(props);

    this._isMounted = false;
    
    var show_count = 12;
    var show_cols = [3,3,3,3,3];
    if(!this.props.print) {
      if((isScreen('xs') || isScreen('sm'))) {
        show_count = 3
        show_cols = [3,3,3,3,3];
      } else {
        if(isScreen('md')) {
          show_count = 6
          show_cols = [3,3,3,3,3];
        }
      }
    }

    this.state = {
      show_count: show_count,
      show_cols: show_cols
    };

    this.selectVenue = this.selectVenue.bind(this);
  }

  selectVenue = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/opsis/venues/' + id);
  }

  render() {

    return (
      <div className="pb-2">

        {this.state ? (
          <div>

              {this.props.venue.engagements && this.props.venue.engagements.length > 0 ? (
                <div>    
                  <h6 className="header-link"><a href="/" onClick={((e) => this.selectVenue(e, this.props.venue.id))}>{this.props.venue.name}</a><span className="pl-2 text-muted">Capacity: <Displays a={parseInt(this.props.venue.capacity)} format="comma" /></span></h6>
                  

                  <Row className="pt-4 pl-2">
                    {this.props.venue.engagements.map((engagement, i) =>
                      (i < this.state.show_count) && engagement.active ? (
                        <Col xs={this.state.show_cols[0]} sm={this.state.show_cols[1]} md={this.state.show_cols[2]} lg={this.state.show_cols[3]} xl={this.state.show_cols[4]} key={i} className="text-left">
                          <Poster id={engagement.poster_id} w={75} />
                          <p className="fs-mini text-left pt-1 pb-0 mb-0">{engagement.short_name || engagement.name}</p>
                          {engagement.dates && engagement.dates.closing && (DateTime.fromISO(engagement.dates.closing) <= DateTime.fromISO(this.props.venue.forecast_date)) ? (
                            <p className="text-left pt-0 mt-0 text-muted">Closed {DateTime.fromISO(engagement.dates.closing).toISODate()}</p>
                          ) : (
                            <>
                              <p className="h4 text-left pt-0 mt-0">
                                <span>${nFormatter(engagement.metrics.weekly_face)}</span>
                                <span className="text-muted h5">
                                  {engagement.metric_type ? (
                                    engagement.metric_type === 'lookback' ? (
                                      <i className="fa fa-square-dollar pl-2" />
                                    ) : (
                                      engagement.metric_type === 'estimate' ? (
                                        <i className="fa fa-sparkles pl-2" />
                                      ) : null
                                    )
                                  ) : null}
                                </span>
                              </p>
                              {engagement.dates && engagement.dates.preview && (DateTime.fromISO(engagement.dates.preview) >= DateTime.fromISO(this.props.venue.forecast_date)) ? (
                                <p className="text-left p-0 m-0 text-muted fs-mini">Preview {DateTime.fromISO(engagement.dates.preview).toISODate()}</p>
                              ) : null}
                              {engagement.dates && engagement.dates.closing && (DateTime.fromISO(engagement.dates.closing)) ? (
                                <p className="text-left p-0 m-0 text-muted fs-mini">Closing {DateTime.fromISO(engagement.dates.closing).toISODate()}</p>
                              ) : null}
                            </>
                          )}
                        </Col>
                      ) : null
                    )}
                  </Row>
                </div>
              ) : (
                <div>
                  <h6 className="header-link"><a href="/" onClick={((e) => this.selectVenue(e, this.props.venue.id))}>{this.props.venue.name}</a></h6>
                  <p className="pt-4 pb-4">No Engagements Scheduled</p>
                </div>
              )}

              <hr className="nopad nomargin"/>

          </div>
        ) : null }
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisVenueCard));
