import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import More from '../../../components/More';
import Displays from '../../../components/Displays';

import { Row, Col } from 'reactstrap';

import { formatNumber } from '../../../core/utils';

class WidgetDashCam extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    isLoaded: PropTypes.bool,
    payload: PropTypes.object,
    more: PropTypes.object
  };

  static defaultProps = {
    print: false,
    className: '',
    title: 'Title',
    description: 'Data',
    isLoaded: false,
    payload: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: this.props.isLoaded
    }

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isLoaded !== this.props.isLoaded) {
      this.setState({
        isLoaded: this.props.isLoaded
      });
    }
    if(prevProps.payload !== this.props.payload) {
      this.setState({
        payload: this.props.payload
      });
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  render() {

    return (
      this.state.isLoaded && this.state.payload ? (
        <>

          <Row>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <h4 className="fw-semi-bold gutter-top-fixed-1">{formatNumber(this.state.payload.headline.format,this.state.payload.headline.value)} <span className="fw-normal text-muted fs-mini">{this.state.payload.headline.title}</span></h4>
            </Col>
            {this.state.payload.headline.yoy ? (
              <Col xs={12} sm={12} md={12} lg={4} xl={4} className="text-center">
                <h6 className="text-muted gutter-top-fixed-2"><Displays a={this.state.payload.headline.yoy} format="simple-percent" /></h6>
              </Col>
            ) : null }
          </Row>

          <div className="d-flex border-top gutter-top-fixed-1">
            {this.state.payload.panels[0] ? (
            <div className="w-50 border-right py-2 px-3">
              <p className="text-muted"><small>{this.state.payload.panels[0].title}</small></p>
              <Row className="gutter-top-fixed-1">
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <h6>{formatNumber(this.state.payload.panels[0].format,this.state.payload.panels[0].value)}</h6>
                </Col>
                {this.state.payload.panels[0].yoy ? (
                  <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                    <h6 className="text-muted fs-mini"><Displays a={this.state.payload.panels[0].yoy} format="simple-percent" /></h6>
                  </Col>
                ) : null }
              </Row>
            </div>
            ) : null }
            {this.state.payload.panels[1] ? (
            <div className="w-50 py-2 pl-3">
              <p className="text-muted"><small>{this.state.payload.panels[1].title}</small></p>
              <Row className="gutter-top-fixed-1">
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  <h6>{formatNumber(this.state.payload.panels[1].format,this.state.payload.panels[1].value)}</h6>
                </Col>
                {this.state.payload.panels[1].yoy ? (
                  <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                    <h6 className="text-muted fs-mini"><Displays a={this.state.payload.panels[1].yoy} format="simple-percent" /></h6>
                  </Col>
                ) : null }
              </Row>
            </div>
            ) : null }
          </div>

          {this.state.payload.panels[2] ? (
            <div className="d-flex border-top">
              {this.state.payload.panels[2] ? (
              <div className="w-50 border-right py-2 px-3">
                <p className="text-muted"><small>{this.state.payload.panels[2].title}</small></p>
                <Row className="gutter-top-fixed-1">
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <h6>{formatNumber(this.state.payload.panels[2].format,this.state.payload.panels[2].value)}</h6>
                  </Col>
                  {this.state.payload.panels[2].yoy ? (
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                      <h6 className="text-muted fs-mini"><Displays a={this.state.payload.panels[2].yoy} format="simple-percent" /></h6>
                    </Col>
                  ) : null }
                </Row>
              </div>
              ) : null }
              {this.state.payload.panels[3] ? (
              <div className="w-50 py-2 pl-3">
                <p className="text-muted"><small>{this.state.payload.panels[3].title}</small></p>
                <Row className="gutter-top-fixed-1">
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <h6>{formatNumber(this.state.payload.panels[3].format,this.state.payload.panels[3].value)}</h6>
                  </Col>
                  {this.state.payload.panels[3].yoy ? (
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                      <h6 className="text-muted fs-mini"><Displays a={this.state.payload.panels[3].yoy} format="simple-percent" /></h6>
                    </Col>
                  ) : null }
                </Row>
              </div>
              ) : null }
            </div>
          ) : null}

          {this.state.payload.panels[4] ? (
            <div className="d-flex border-top">
              {this.state.payload.panels[4] ? (
              <div className="w-50 border-right py-2 px-3">
                <p className="text-muted"><small>{this.state.payload.panels[4].title}</small></p>
                <Row className="gutter-top-fixed-1">
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <h6>{formatNumber(this.state.payload.panels[4].format,this.state.payload.panels[4].value)}</h6>
                  </Col>
                  {this.state.payload.panels[4].yoy ? (
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                      <h6 className="text-muted fs-mini"><Displays a={this.state.payload.panels[4].yoy} format="simple-percent" /></h6>
                    </Col>
                  ) : null }
                </Row>
              </div>
              ) : null }
              {this.state.payload.panels[5] ? (
              <div className="w-50 py-2 pl-3">
                <p className="text-muted"><small>{this.state.payload.panels[5].title}</small></p>
                <Row className="gutter-top-fixed-1">
                  <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                    <h6>{formatNumber(this.state.payload.panels[5].format,this.state.payload.panels[5].value)}</h6>
                  </Col>
                  {this.state.payload.panels[5].yoy ? (
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
                      <h6 className="text-muted fs-mini"><Displays a={this.state.payload.panels[5].yoy} format="simple-percent" /></h6>
                    </Col>
                  ) : null }
                </Row>
              </div>
              ) : null }
            </div>
          ) : null}

          {this.props.more ? (
            <More className="gutter-top-fixed-2" url={this.props.more.url} tab={this.props.more.tab} />
          ) : null}
        
        </>
      ) : null
    )
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetDashCam));
