import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Nav,
  NavItem,
  Button,
  ButtonGroup
} from 'reactstrap';

import WidgetLogs from '../../widgets/admin/Logs';
import WidgetBlocklist from '../../widgets/admin/Blocklist';

import isScreen from '../../core/screenHelper';

class Admin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    };

    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  setTabs(){

      var tabSelected = 0;
      var tabs = [
        {
          id: 0,
          icon: 'fa-database',
          name: 'Logs'
        },
        {
          id: 1,
          icon: 'fa-list',
          name: 'Blocklist'
        }
      ];
      
      this.setState({
        tabs: tabs,
        tabSelected: tabSelected
      });

  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  render() {
  
    return (
      <div>

        <div className="menuContainer lg">
          <Nav className="gutter-top-fixed-2">
            <NavItem className="nopad-bottom nomargin-bottom">
              
                <h5 className="text-danger">Admin</h5>

                {this.state.tabs ? (
                <ButtonGroup>
                  {this.state.tabs.map((tab, i) =>
                    <Button key={i}
                      color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                      active={this.state.tabSelected === i}
                    ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                    <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                  )}
                </ButtonGroup>
                ) : null}
            </NavItem>
          </Nav>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === 0 ? (
            <div>
              <WidgetLogs />
            </div>
          ): null}

          {this.state.tabSelected === 1 ? (
            <div>
              <WidgetBlocklist />
            </div>
          ): null}
        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Admin));
