import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';

import { Row, Col, Button, Table } from 'reactstrap';

import { scurl, surl, displayBudgetMetric, changeBudgetMetric, budgetMetricFormat } from '../../../core/utils.js';

import Loading from '../../../components/Loading';
import More from '../../../components/More';
import Displays from '../../../components/Displays';

import WeekCard from './cards/Week.js';

import Select from 'react-select';

import moment from 'moment';
import { DateTime } from 'luxon';

class WidgetOpsisPaceWeeks extends React.Component {

  static propTypes = {
    engagement: PropTypes.object,
    chart_metric: PropTypes.string,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    chart_timespan: PropTypes.string,
    trends: PropTypes.bool,
    dashboard: PropTypes.bool,
    budget_metric: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    chart_metric: 'qty',
    chartAspectWidth: 4,
    chartAspectHeight: 1,
    print: false,
    chart: true,
    width: 1050,
    height: 250,
    chart_timespan: 'week',
    trends: false,
    dashboard: false,
    budget_metric: 'net',
    className: ''
  }

  constructor(props) {
    super(props); 


    this.state = {
      isLoaded: false,
      showPriorWeeks: false,
      showFutureWeeks: false,
      chart_metric: this.props.chart_metric,
      chart_timespan: this.props.chart_timespan,
      budget_metric: this.props.budget_metric,
      format: 'XM'
    };

    this._isMounted = false;

    this.getForecasts = this.getForecasts.bind(this);
    this.loadForecast = this.loadForecast.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.getForecasts();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  togglePrior = () => { 
    this.setState({
      showPriorWeeks: !this.state.showPriorWeeks
    });
  }

  toggleFuture= () => { 
    this.setState({
      showFutureWeeks: !this.state.showFutureWeeks
    });
  }

  getForecasts() {

    var opts = {
      path: '/v2/opsis/settings',
      type: 'simple'
    };

    //console.log(opts);

    var url = surl(opts);
    console.log(url);

    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {

          var items = [];
          for(var forecast of result.results.forecast_dates) {
            if(forecast.include) {
              items.push({
                value: forecast.date,
                label: forecast.name
              });
            }
          }

          if(items.length > 0) {
            this.setState({
              ...this.state,
              forecasts: {
                ...this.state.forecasts,
                isLoaded: true,
                items: items,
                selected: items[items.length-1]
              },
              comparisons: {
                ...this.state.comparisons,
                isLoaded: true,
                items: items,
                selected: items[0]
              }
            }, function() {
              this.loadForecast();
            });
          } else {
            alert('Forecasts not loaded correctly. Check with admin.');
          }

        },
        (error) => {
          this.setState({
              ...this.state,
              forecasts: {
                ...this.state.forecasts,
                isLoaded: true,
                error: error
              }
          }, function() {
            this.loadForecast()
          });
      }
    );
  }

  loadForecast() {
    
    this.setState({
      isLoaded: false,
      results: null,
      tabSelected: -1
    }, function() {

      var opts = {
        path: '/v2/opsis/pacing?forecast_date=' + this.state.forecasts.selected.value + '&forecast_start=2024-07-01&forecast_end=2025-06-29',
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  handleForecastChange = selectedOption => {

    var hasChanged = true;

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      forecasts: {
        ...this.state.forecasts,
        selected: selectedOption
      }
    }, function() {
      this.loadForecast();
    });
  };

  changeBudgetMetric = () => { 
    this.setState({
      budget_metric: changeBudgetMetric(this.state.budget_metric),
    }, function() {
      //this.setDashCam()
    });
  };

  render() {
    return (

      this.props.dashboard ? (
        <Widget
          className={[this.props.className,'mb-0 h-100'].join(' ')}
          bodyClass={`mt p-0`}
          title={
            <div className="d-flex justify-content-between flex-wrap">
              <h5 className="pt-1"><span>Forecast Pacing</span><small className="text-muted pl-2">by <button className="header-link" onClick={() => this.changeBudgetMetric()}>{displayBudgetMetric(this.state.budget_metric)}</button>.</small></h5>
            </div>
          }
        >

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
          {this.state.isLoaded ? (
            <>
              <Table className="fs-mini gutter-top-fixed-2 border">
                <thead>
                  <tr>
                      <th width="33%">Week</th>
                      <th className="text-center" width="33%">Pace</th>
                      <th className="text-center" width="33%">+/-</th>
                  </tr>
                </thead>
                <tbody> 
                {this.state.results.weeks.map((week, i) =>
                  (DateTime.now() < DateTime.fromISO(week.weekending).plus({days: 5})) && (DateTime.now().plus({weeks: 5 }) > DateTime.fromISO(week.weekending)) ? (
                    <tr key={i}>
                      <td>
                        <p className="m-0 p-0">{DateTime.fromISO(week.weekending).toFormat('LLL dd')}</p>
                      </td>
                      <td className="text-center">
                        <p className="m-0 p-0">{budgetMetricFormat(this.state.budget_metric,week.ticketing.projected[this.state.budget_metric])}</p>
                      </td>
                      <td className="text-center">
                        <p className="m-0 p-0"><Displays a={week.ticketing.projected[this.state.budget_metric]} b={week.ticketing.forecast[this.state.budget_metric]} format="percent" /></p>
                      </td>
                    </tr>
                  ) : null
                )}
                </tbody>
              </Table>
              <More className="gutter-right-fixed-2" url="/app/opsis" tab={0} />
            </>

          ) : null}

        </Widget>
      ) : (
        <Widget title={
          <div className="d-flex justify-content-between flex-wrap">
            <h5><span>Budget Pacing</span><small className="text-muted pl-2">Set to track annual budget model.</small></h5>
            {this.state && this.state.forecasts ? (
                <Select
                  value={this.state.forecasts.selected}
                  onChange={this.handleForecastChange}
                  options={this.state.forecasts.items}
                  placeholder='Select forecast...'
                  isMulti={false}
                /> 
            ) : null}
          </div>
        }>
          
          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
          
          {this.state.isLoaded ? (

            <div className="gutter-top-fixed-2 gutter-bottom-fixed-2">

              <Row>
                <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2">
                  <br/>WEEKENDING
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
                  <br/>DEFERRED
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
                  <br/>PACE
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
                  <br/>FORECAST
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
                  <br/>+/-
                </Col>
                <Col xs={12} sm={1} md={1} lg={1} xl={1} className="gutter-top-fixed-2 text-center d-print-none">
                  <div className="text-right">
                    <Button className="btn-small btn-success text-white gutter-right-fixed-4" onClick={() => this.togglePrior()}>
                      {!this.state.showPriorWeeks ? (
                        <i className="fa-light fa-plus" />
                      ) : (
                        <i className="fa-light fa-minus" />
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>

              <hr/>

              {this.state.results.weeks.map((week, i) =>
                <div key={i} className="w-100">
                  {moment(week.weekending, 'YYYY-MM-DD').isSameOrAfter(moment().add(-3,'days')) ? (
                    (week.ticketing.projected.qty !== week.ticketing.forecast.qty) ? (
                      <WeekCard key={i} print={this.props.print} trends={this.props.trends} week={week} />
                    ) : (
                      this.state.showFutureWeeks ? (
                        <WeekCard key={i} print={this.props.print} trends={this.props.trends} week={week} />
                      ) : null
                    )
                  ) : (
                    this.state.showPriorWeeks ? (
                      <WeekCard key={i} print={this.props.print} trends={this.props.trends} week={week} />
                    ) : null
                  )}
                  
                </div>
              )}

              {!this.state.showFutureWeeks ? (
                <div className="text-right gutter-right-fixed-4 d-print-none">
                  <Button className="btn-primary text-white" onClick={() => this.toggleFuture()}>
                    <i className="fa-light fa-arrow-rotate-right" /> SHOW FUTURE WEEKS
                  </Button>
                </div>
              ) : null}
            
            </div>
            
          ) : null}

        </Widget>
      )
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisPaceWeeks));
