import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  ButtonGroup
} from 'reactstrap';

import WidgetShare from '../../widgets/universal/Share';

import WidgetSpacer from '../../widgets/utils/Spacer';

import Select from 'react-select';

import isScreen from '../../core/screenHelper';

class Ga extends React.Component {

  constructor(props) {
    super(props);

    let items = [
      { value: '', label: '----' },
      { value: 'channel:Google', label: 'Channel: Google' },
      { value: 'channel:Microsoft', label: 'Channel: Microsoft' },
      { value: '', label: '----' },
      { value: 'type:Generic', label: 'Type: Generic' },
      { value: 'type:Shows', label: 'Type: Shows' },
      { value: 'type:International', label: 'Type: International' },
      { value: 'type:- US', label: 'Type: Domestic' },
      { value: 'type:- NY', label: 'Type: NYC' },
    ];

    let ga_filter = {
      items: items,
      isLoaded: true
    }

    this.state = {
      isLoaded: false,
      ga_filter: ga_filter,
      ga_filter_string: ''
    };

    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  setTabs(){

      var tabSelected = 1;
      var tabs = [
        {
          id: 0,
          icon: 'fa-circle-dollar-to-slot',
          name: 'Transactions'
        },
        {
          id: 1,
          icon: 'fa-print',
          name: 'Print'
        },
      ];
      
      this.setState({
        tabs: tabs,
        tabSelected: tabSelected
      });

  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  handleFilterChange = selectedOption => {

    var hasChanged = true;

    let ga_filter_string = '';
    for(var [i,selected] of selectedOption.entries()) {
      if(i > 0) {
        ga_filter_string += ',';
      }
      ga_filter_string += selected.value;
    }

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      ga_filter: {
        ...this.state.ga_filter,
        selected: selectedOption
      },
      ga_filter_string: ga_filter_string
    });
  };

  render() {
  
    return (
      <div>

        <div className="menuContainer lg">

          <Row>
            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
              <Nav className="mt-4">
                <NavItem className="nopad-bottom nomargin-bottom">
                  
                    <h5 className="text-danger">GA4: Analytics</h5>

                    {this.state.tabs ? (
                      <ButtonGroup>
                        {this.state.tabs.map((tab, i) =>
                          <Button key={i}
                            color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                            active={this.state.tabSelected === i}
                          ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                          <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                        )}
                      </ButtonGroup>
                    ) : null}
                </NavItem>
              </Nav>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={6} className="gutter-top-fixed-2 d-md-down-none">
              <Row>
                <Col xs={12} sm={12} md={8} lg={8} xl={5}>
                  <h6>FILTER <span className="text-muted fs-mini">only one per category</span></h6>  
                  <Select
                    value={this.state.ga_filter.selected}
                    onChange={this.handleFilterChange}
                    options={this.state.ga_filter.items}
                    placeholder='Filter results...'
                    isMulti={true}
                  /> 
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === 0 ? (
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <WidgetShare print={false} title="Device Type" ga_filter={this.state.ga_filter_string} ga_property="device.type" description="GA4 transaction data" />
                <WidgetShare className="mt-3" print={false} title="New or Returning" ga_filter={this.state.ga_filter_string} ga_property="session.type" description="GA4 transaction data" />
                <WidgetShare className="mt-3" print={false} title="Country" ga_filter={this.state.ga_filter_string} ga_property="geo.country" description="GA4 transaction data" />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <WidgetShare print={false} title="Traffic Source" ga_filter={this.state.ga_filter_string} ga_property="source.source" description="GA4 transaction data" />
                <WidgetShare className="mt-3" print={false} title="Traffic Medium" ga_filter={this.state.ga_filter_string} ga_property="source.medium" description="GA4 transaction data" />
              </Col>
            </Row>
          ): null}

          {this.state.tabSelected === 1 ? (
            <div>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <WidgetShare className="mt-3" print={true} title="Traffic Source" ga_filter={this.state.ga_filter_string} ga_property="source.source" description="GA4 transaction data" />
                  <WidgetShare className="mt-3" print={true} title="Device Type" ga_filter={this.state.ga_filter_string} ga_property="device.type" description="GA4 transaction data" />
                  <WidgetShare className="mt-3" print={true} title="New or Returning" ga_filter={this.state.ga_filter_string} ga_property="session.type" description="GA4 transaction data" />
                  
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <WidgetShare className="mt-3" print={true} title="Traffic Medium" ga_filter={this.state.ga_filter_string} ga_property="source.medium" description="GA4 transaction data" />
                  <WidgetShare className="mt-3" print={true} title="Country" ga_filter={this.state.ga_filter_string} ga_property="geo.country" description="GA4 transaction data" />
                </Col>
              </Row>
              <WidgetSpacer print={true} />
            </div>
          ): null}
        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Ga));
