import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Widget from '../../components/Widget';
import s from './Login.module.scss';
import Cookies from 'js-cookie';
import { setFilter } from '../../actions/filter';

class Login extends React.Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    var q = new URL(window.location.href.replace('#/',''));
    var token = q.searchParams.get('token');
    if(token) {
      Cookies.set('sh_token', token, { expires: 7, path: '' });

      this.props.dispatch(setFilter());

      this.props.history.push('/');
    } else {

      Cookies.remove("sh_token");
      localStorage.removeItem("sh_state");

      var url = 'https://api.shadow.haus/users/auths/gmail';
      if(window.location.href.includes('localhost')) {
        url = 'http://127.0.0.1:5050/users/auths/gmail';
      }
  
      this.state = {
        url: url
      };
    }
  }

  render() {
    return (
      <div className={s.root}>
        <Container>
          <Widget className={`${s.widget} mx-auto`} title={<h3 className="mt-0"><span className="text-danger">SH</span>3</h3>}>
            <p className={s.widgetLoginInfo}>
              Please sign in to access
            </p>
            <div className="gutter-top-fixed-4 text-center">
              {this.state && this.state.url ? (
                <a href={this.state.url} className="btn btn-inverse btn-sm"><i className="fa-brands fa-google fa-lg"></i>&nbsp;&nbsp;Login With Google</a>
              ) : null}
            </div>
          </Widget>
        </Container>
        <footer className={s.footer}>
          SH3
        </footer>
      </div>
    );
  }
}

export default withRouter(connect()(Login));

