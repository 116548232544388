import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Row,
  Col
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Displays from '../../../components/Displays';
import Loading from '../../../components/Loading';
import { scurl, filterUpdated, changeSalesMetric, changeAffiliateSalesMetric, displaySalesMetric, displayPreSalesMetric } from '../../../core/utils';

class WidgetBwLead extends React.Component {

  static propTypes = {
    product_id: PropTypes.number,
    print: PropTypes.bool,
    sales_metric: PropTypes.string,
    affiliate: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    print: false,
    sales_metric: 'face',
    affiliate: true,
    className: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      sales_metric: this.props.sales_metric
    }

    this._isMounted = false;
    this.loadSales = this.loadSales.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadSales();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'LEAD WIDGET')) {
      this.loadSales();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadSales() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/transactions/lead',
        type: 'universal'
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  changeSalesMetric = () => { 
    var change_display = '';
    if(this.props.affiliate) {
      change_display = changeAffiliateSalesMetric(this.state.sales_metric);
    } else {
      change_display = changeSalesMetric(this.state.sales_metric);
    }
    this.setState({
      sales_metric: change_display
    });
  };

  render() {

    var title = <h5><span className="text-danger">Lead Times</span><small className="text-muted"> Transaction Date <button className="header-link" onClick={() => this.changeSalesMetric()}>{displaySalesMetric(this.state.sales_metric)}</button>, by Performance Date week. Gray figures under each week represent the percentage of that total category (sale, refund) that fell in that performance week.</small></h5>;

    return (

      <div>

        <Widget title={title} className={this.props.className}>

          <Loading loading={!this.state.isLoaded} pad={10} />

          {this.state.isLoaded ? (

            <div className="gutter-top-fixed-4">

              <Row className="mb-4">
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <p className="fs-mini text-center">Sales</p>
                  <p className="text-center"><strong>{displayPreSalesMetric(this.state.sales_metric)}<Displays a={parseInt(this.state.results.totals.dates[0].sales[this.state.sales_metric])} format="comma" /></strong></p>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <p className="fs-mini text-center">Refunds</p>
                  <p className="text-center"><strong>{displayPreSalesMetric(this.state.sales_metric)}<Displays a={parseInt(this.state.results.totals.dates[0].refunds[this.state.sales_metric])} format="comma" /></strong></p>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <p className="fs-mini text-center">Net</p>
                  <p className="text-center"><strong>{displayPreSalesMetric(this.state.sales_metric)}<Displays a={parseInt(this.state.results.totals.dates[0].net[this.state.sales_metric])} format="comma" /></strong></p>
                </Col>
              </Row>

              <hr/>

              {this.state.results.data.week.map((block, i) =>
                <div key={i}>
                  <p className="text-center mt-4"><strong>{moment(block.key, 'YYYY-MM-DD').format('MMM DD')}</strong></p>
                  <Row className="text-center">
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                      <p className={this.props.print ? 'fs-mini' : null}>{displayPreSalesMetric(this.state.sales_metric)}<Displays a={parseInt(block.sales[this.state.sales_metric])} format="comma" /></p>
                      <p className="fs-mini text-muted">{parseFloat( (Math.abs(block.sales.face)/this.state.results.totals.dates[0].sales.face)*100 ).toFixed(1)}%</p>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                      <p className={this.props.print ? 'fs-mini' : null}>{displayPreSalesMetric(this.state.sales_metric)}<Displays a={parseInt(block.refunds[this.state.sales_metric])} format="comma" /></p>
                      <p className="fs-mini text-muted">{parseFloat( (Math.abs(block.refunds.face)/Math.abs(this.state.results.totals.dates[0].refunds.face))*100 ).toFixed(1)}%</p>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                      <p className={this.props.print ? 'fs-mini' : 'pt-3'}><strong>{displayPreSalesMetric(this.state.sales_metric)}<Displays a={parseInt(block.net[this.state.sales_metric])} format="comma" /></strong></p>
                    </Col>
                  </Row>
                  <hr/>
                </div>
              )}

            </div>
            
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetBwLead));
