import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { scurl, filterUpdated, comma } from '../../../../core/utils.js';
import Loading from '../../../../components/Loading';

import Poster from '../../../../components/Poster';

import {
  Row,
  Col,
  Nav,
  NavItem,
  Input,
  InputGroup,
  Button,
  ButtonGroup
} from 'reactstrap';

import ChartCard from './Chart.js';

class WidgetOpsisEngagementCard extends React.Component {

  static propTypes = {
    engagement: PropTypes.object,
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      engagement: this.props.engagement
    };

    this._isMounted = false;
    this.setPrior = this.setPrior.bind(this);
    this.setCharts = this.setCharts.bind(this);
    this.handleMetric = this.handleMetric.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.setDefault = this.setDefault.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if(!this.props.engagement.tba && !this.props.engagement.forecast_closed) {
      this.setPrior();
    } else {
      this.setState({
        isLoaded: true
      });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SALES WIDGET')) {
      if(this._isMounted && !this.props.engagement.forecast_closed && !this.props.engagement.tba) {
        this.setPrior();
      } else {
        this.setState({
          isLoaded: true
        });
      }
    }
  }

  setPrior = () => {

    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/opsis/products/' + this.props.engagement.id + '?venue_id=' + this.props.engagement.venue.id,
        type: 'universal'
      };

      if(this.props.engagement.dates.preview && moment(this.props.engagement.dates.preview, 'YYYY-MM-DD').isAfter(moment(this.props.filter.dates.end, 'YYYY-MM-DD'))) {
        //opts.perf_date = false;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            }, function() {
              this.setCharts();
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    }); 

  }

  setCharts = () => {
     
    if(this.state.results && this.state.results.weeks && this.state.results.future_weeks && ( (this.state.results.weeks.length > 0) || (this.state.results.future_weeks.length > 0) ) ) {

      var charts = [
        {
          type: 4,
          data: [],
          properties: {
            max: 1.2,
            min: 0.8
          },
          prefix: '$',
          decimals: 0
        },
        {
          type: 2,
          data: [],
          properties: {
            max: 1.1,
            min: 0.9
          },
          prefix: '$',
          decimals: 2
        },
        {
          type: 3,
          data: [],
          properties: {
            max: 1.1,
            min: 0.9
          },
          prefix: '',
          suffix: '%',
          decimals: 1
        },
        {
          type: 2,
          data: [],
          properties: {
            max: 1.1,
            min: 0.9
          },
          prefix: '$',
          decimals: 2
        },
        {
          type: 2,
          data: [],
          properties: {
            max: 1.1,
            min: 0.9
          },
          prefix: '$',
          decimals: 2
        },
      ];

      var showChart = false;
      this.state.results.weeks.forEach(function(block, i) {
        
        if(block.years[0]['face'] > 0) {
          showChart = true;
        }

        var data0 = {
          name: block.dates.end,
          year1: block.weighted['face'],
          year1_unweighted: block.years[0]['face'],
          year1_label: 'Weighted',
          year1_unweighted_label: 'Unweighted'
        };
        if(block.prior_year_weighted && block.prior_year_weighted['face'] && (block.prior_year_weighted['face'] > 0)) {
          data0.year2 = block.prior_year_weighted['face'];
          data0.year2_label = 'Prior Year';
        }
        charts[0].data.push(data0);

        var data1 = {
          name: block.dates.end,
          year1: parseFloat(block.years[0]['face']/block.years[0]['qty']),
          year1_label: moment(block.dates.end,'YYYY-MM-DD').format('YYYY')
        };
        if(block.prior_year_weighted && block.prior_year_weighted['face'] && (block.prior_year_weighted['face'] > 0)) {
          data1.year2 = parseFloat(block.years[1]['face']/block.years[1]['qty']);
          data1.year2_label = 'Prior Year';
        }
        charts[1].data.push(data1);

        var data2 = {
          name: block.dates.end,
          year1: parseFloat((block.years[0]['fees']/block.years[0]['face'])*100),
          year1_label: moment(block.dates.end,'YYYY-MM-DD').format('YYYY')
        };
        if(block.prior_year_weighted && block.prior_year_weighted['face'] && (block.prior_year_weighted['face'] > 0)) {
          data2.year2 = parseFloat((block.years[1]['fees']/block.years[1]['face'])*100);
          data2.year2_label = 'Prior Year';
        }
        charts[2].data.push(data2);

        var data3 = {
          name: block.dates.end,
          year1: parseFloat((block.years[0]['access_fees']/block.years[0]['qty'])),
          year1_label: moment(block.dates.end,'YYYY-MM-DD').format('YYYY')
        };
        if(block.prior_year_weighted && block.prior_year_weighted['face'] && (block.prior_year_weighted['face'] > 0)) {
          data3.year2 = parseFloat((block.years[1]['access_fees']/block.years[1]['qty']));
          data3.year2_label = 'Prior Year';
        }
        charts[3].data.push(data3);

        var data4 = {
          name: block.dates.end,
          year1: parseFloat((block.years[0]['affiliate_commission']/block.years[0]['qty'])),
          year1_label: moment(block.dates.end,'YYYY-MM-DD').format('YYYY')
        };
        if(block.prior_year_weighted && block.prior_year_weighted['face'] && (block.prior_year_weighted['face'] > 0)) {
          data4.year2 = parseFloat((block.years[1]['affiliate_commission']/block.years[1]['qty']));
          data4.year2_label = 'Prior Year';
        }
        charts[4].data.push(data4);
       
      });

      this.state.results.future_weeks.forEach(function(block, i) {
        
        if(block.years[0]['face'] > 0) {
          showChart = true;
        }

        var data0 = {
          name: block.dates.end,
          future: block.years[0]['face'],
          future_label: 'Deferred',
        };
        charts[0].data.push(data0);

        var data1 = {
          name: block.dates.end,
          future: parseFloat(block.years[0]['face']/block.years[0]['qty']),
          future_label: moment(block.dates.end,'YYYY-MM-DD').format('YYYY')
        };
        charts[1].data.push(data1);

        var data2 = {
          name: block.dates.end,
          future: parseFloat((block.years[0]['fees']/block.years[0]['face'])*100),
          future_label: moment(block.dates.end,'YYYY-MM-DD').format('YYYY')
        };
        charts[2].data.push(data2);

        var data3 = {
          name: block.dates.end,
          future: parseFloat((block.years[0]['access_fees']/block.years[0]['qty'])),
          future_label: moment(block.dates.end,'YYYY-MM-DD').format('YYYY')
        };
        charts[3].data.push(data3);

        var data4 = {
          name: block.dates.end,
          future: parseFloat((block.years[0]['affiliate_commission']/block.years[0]['qty'])),
          future_label: moment(block.dates.end,'YYYY-MM-DD').format('YYYY')
        };
        charts[4].data.push(data4);
       
      });

      if(showChart) {
        this.setState({
          charts: charts
        });
      }

    } else {
      this.setState({
        chart: null
      });
    }
  };
  
  
  handleMetric = (e) => { 
    this.setState({
      engagement: { 
        ...this.state.engagement, 
        metrics: {
          ...this.state.engagement.metrics,
          [e.target.id]: e.target.value
        }
      }
    });
  }

  handleDate = (e) => { 
    this.setState({
      engagement: { 
        ...this.state.engagement, 
        dates: {
          ...this.state.engagement.dates,
          [e.target.id]: e.target.value
        }
      }
    });
  }

  setDefault = (e, value) => { 

    let fixed = 2;
    if(e.target.id === 'btn_markup') {
      fixed = 4;
    }

    this.setState({
      engagement: { 
        ...this.state.engagement, 
        metrics: {
          ...this.state.engagement.metrics,
          [e.target.id.replace('btn_','')]: parseFloat(value).toFixed(fixed)
        }
      }
    });
  }

  editShortName = () => { 
    this.setState({
      edit_short_name: true
    });
  }

  handleShortName = (e) => { 
    this.setState({
      engagement: { 
        ...this.state.engagement, 
        edit_short_name: e.target.value
      }
    });
  }

  save = () => { 

    this.setState({
      isLoaded: false
    }, function() {
      
      var valid = true;

      var engagement = this.state.engagement;
      engagement.metrics.ticket_face = parseFloat(this.state.engagement.metrics.ticket_face);
      engagement.metrics.markup = parseFloat(parseFloat(this.state.engagement.metrics.markup).toFixed(4));
      engagement.metrics.access = parseFloat(this.state.engagement.metrics.access);
      engagement.metrics.affiliate = parseFloat(this.state.engagement.metrics.affiliate);
      engagement.metrics.weekly_face = parseFloat(this.state.engagement.metrics.weekly_face);

      var url = '/v2/opsis/products/' + this.props.engagement.id + '/update?';
      url += '&venue_id=' + this.props.engagement.venue.id;
      url += '&weekly_face=' + engagement.metrics.weekly_face;
      url += '&ticket_face=' + engagement.metrics.ticket_face;
      url += '&markup=' + engagement.metrics.markup;
      url += '&access=' + engagement.metrics.access;
      url += '&affiliate=' + engagement.metrics.affiliate;
      if(engagement.dates.preview) {
        url += '&preview=' + engagement.dates.preview;
        //valid = false;
      }
      if(engagement.dates.closing) {
        url += '&closing=' + engagement.dates.closing;
      }
      if(engagement.edit_short_name) {
        url += '&short_name=' + engagement.edit_short_name;
      }

      if(valid) {

        var opts = {
          path: url,
          type: 'simple'
        };

        scurl(opts, function(err, results) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            //console.log(this.state.engagement);

            var opts = {
              path: '/v2/opsis/fire?venue_id=' + this.props.engagement.venue.id,
              type: 'simple'
            };

            scurl(opts, function(err, results) {
              if(this._isMounted) {
                if(err) {
                  this.setState({
                    error: {
                      json: err,
                      status: err.status || -99,
                      message: err.message || 'Error occured'
                    }
                  });
                } else {
                  window.location.reload();
                }
              } else {
                console.log('handled unmount');
              }
            }.bind(this));
          }
        }.bind(this));
      } else {
        console.log('Not valid');
        console.log(url);
      }
    });
  }

  delete = () => { 

    this.setState({
      isLoaded: false
    }, function() {
      
      var url = '/v2/opsis/products/' + this.props.engagement.id + '/update?';
      url += '&venue_id=' + this.props.engagement.venue.id;
      url += '&delete=1';

      var opts = {
        path: url,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {

          console.log('RUN FIRE');

          var opts = {
            path: '/v2/opsis/fire?venue_id=' + this.props.engagement.venue.id,
            type: 'simple'
          };

          scurl(opts, function(err, results) {
            if(this._isMounted) {
              if(err) {
                this.setState({
                  error: {
                    json: err,
                    status: err.status || -99,
                    message: err.message || 'Error occured'
                  }
                });
              } else {
                window.location.reload();
              }
            } else {
              console.log('handled unmount');
            }
          }.bind(this));
        }
      }.bind(this));

    });
  }

  render() {

    return (
      <div className="card gutter-bottom-fixed-4 bg-dark">
        <div className="card-body">
            
            <Row>
              <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                <Nav>
                  <NavItem className="nopad-bottom nomargin-bottom">
                    {this.props.engagement.poster_id ? (
                      <Poster id={this.props.engagement.poster_id} w={75} />
                    ) : (
                      <Poster w={75} />
                    )}
                  </NavItem>
                  <NavItem className="pt-0 pl-3">
                    <h4 className="page-title text-white">
                      <span className={!this.state.edit_short_name ? 'pr-3' : 'd-none'}>{this.props.engagement.short_name || this.props.engagement.name}</span>
                      <span>
                        <Input id="edit_short_name" type="text" placeholder={this.props.engagement.short_name || this.props.engagement.name} className={this.state.edit_short_name ? 'm-0' : 'd-none'} disabled={false} onChange={(e) => this.handleShortName(e)} />
                        <Button className={!this.state.edit_short_name ? 'btn btn-link text-white' : 'd-none'} onClick={() => this.editShortName()}>
                          <i className="fa-light fa-edit" />
                        </Button>
                      </span><br/>
                      {this.props.engagement.forecast_closed ? (
                        <p className="small text-light">CLOSED {this.props.engagement.dates.closing}</p>
                      ) : this.props.engagement.tba ? (
                        <p className="small text-light">TBA</p>
                      ) : (
                        <p className="small text-light">SCHEDULED</p>
                      )}
                      <button className="header-link fs-mini text-light" onClick={() => this.delete()}>remove</button>
                    </h4>
                  </NavItem>
                </Nav>
              </Col>
              {!this.props.engagement.forecast_closed ? (
              <Col xs={12} sm={4} md={4} lg={3} xl={3}>
                <div className="text-right">
                  <Button className={this.state.isLoaded ? 'btn-light' : 'btn-danger text-white'} onClick={() => this.save()} disabled={!this.state.isLoaded}>
                    <i className="fa-light fa-save" /> &nbsp;SAVE
                  </Button>
                </div>
              </Col>
              ) : null}
            </Row>

            {!this.props.engagement.forecast_closed ? (
              <div>
                <hr className="bg-light" />

                <div className="pt-2">

                  <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} className="text-white" />
                  
                  {this.state.isLoaded ? (
                    <div>
                      <Row>
                        <Col xs={12} sm={9} md={9} lg={9} xl={9}>
                          <h3 className="text-warning">Weekly Face: <span className="text-white">${comma(parseFloat(this.state.engagement.metrics.weekly_face).toFixed(0))}</span></h3>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                          <InputGroup>
                            <Input id="weekly_face" bsSize="16" type="text" value={parseFloat(this.state.engagement.metrics.weekly_face).toFixed(0)} onChange={(e) => this.handleMetric(e)} />
                            {this.state.results && this.state.results.weighted_totals && (this.state.results.weighted_totals.qty > 0) ?  (
                              <div className="input-group-append">
                                <ButtonGroup>
                                  <Button id="btn_weekly_face" color="danger" onClick={(e) => this.setDefault(e, parseFloat((this.state.results.weighted_totals.avg_week)))}>{'$' + comma(parseFloat(this.state.results.weighted_totals.avg_week).toFixed())}</Button>
                                </ButtonGroup>
                              </div>
                            ) : null }
                          </InputGroup>
                        </Col>
                      </Row>

                      {this.state.charts ? (
                        <ChartCard print={this.props.print} chart={this.state.charts[0]} />
                      ) : null}

                      <hr className="bg-light" />

                      <Row className="mt-4">
                        <Col xs={12} sm={9} md={9} lg={9} xl={9}>
                          <h3 className="text-warning">Per Ticket: <span className="text-white">${parseFloat(this.state.engagement.metrics.ticket_face).toFixed(2)}</span></h3>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                          <InputGroup>
                            <Input id="ticket_face" bsSize="16" type="text" value={this.state.engagement.metrics.ticket_face} onChange={(e) => this.handleMetric(e)} />
                            {this.state.results && this.state.results.weighted_totals && (this.state.results.weighted_totals.qty > 0) ?  (
                              <div className="input-group-append">
                                <ButtonGroup>
                                  <Button id="btn_ticket_face" color="danger" onClick={(e) => this.setDefault(e, parseFloat(this.state.results.totals.average_price))}>{'$' + parseFloat(this.state.results.totals.average_price).toFixed(2)}</Button>
                                </ButtonGroup>
                              </div>
                            ) : null }
                          </InputGroup>
                        </Col>
                      </Row>

                      {this.state.charts ? (
                        <ChartCard print={this.props.print} chart={this.state.charts[1]} />
                      ) : null}

                      <hr className="bg-light" />

                      <Row className="mt-4">
                        <Col xs={12} sm={9} md={9} lg={9} xl={9}>
                          <h3 className="text-warning">Markup: <span className="text-white">{parseFloat(this.state.engagement.metrics.markup*100).toFixed(2)}%</span></h3>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                          <InputGroup>
                            <Input id="markup" bsSize="16" type="text" value={this.state.engagement.metrics.markup} onChange={(e) => this.handleMetric(e)} />
                            {this.state.results && this.state.results.totals.qty > 0 ?  (
                              <div className="input-group-append">
                                <ButtonGroup>
                                  <Button id="btn_markup" color="danger" onClick={(e) => this.setDefault(e, parseFloat(this.state.results.totals.margin))}>{parseFloat(this.state.results.totals.margin * 100).toFixed(2) + '%'}</Button>
                                </ButtonGroup>
                              </div>
                            ) : null }
                          </InputGroup>
                        </Col>
                      </Row>

                      {this.state.charts ? (
                        <ChartCard print={this.props.print} chart={this.state.charts[2]} />
                      ) : null}

                      <hr className="bg-light" />

                      <Row className="mt-4">
                        <Col xs={12} sm={9} md={9} lg={9} xl={9}>
                          <h3 className="text-warning">Access Fee: <span className="text-white">${parseFloat(this.state.engagement.metrics.access).toFixed(2)}</span></h3>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                          <InputGroup>
                            <Input id="access" bsSize="16" type="text" value={this.state.engagement.metrics.access} onChange={(e) => this.handleMetric(e)} />
                            {this.state.results && this.state.results.weighted_totals && (this.state.results.weighted_totals.qty > 0) ?  (
                              <div className="input-group-append">
                                <ButtonGroup>
                                  <Button id="btn_access" color="danger" onClick={(e) => this.setDefault(e, parseFloat(this.state.results.totals.access_fees/this.state.results.totals.qty))}>{'$' + parseFloat(this.state.results.totals.access_fees/this.state.results.totals.qty).toFixed(2)}</Button>
                                </ButtonGroup>
                              </div>
                            ) : null }
                          </InputGroup>
                        </Col>
                      </Row>

                      {this.state.charts ? (
                        <ChartCard print={this.props.print} chart={this.state.charts[3]} />
                      ) : null}

                      <hr className="bg-light" />

                      <Row className="mt-4">
                        <Col xs={12} sm={9} md={9} lg={9} xl={9}>
                          <h3 className="text-warning">Affiliate Fee: <span className="text-white">${parseFloat(this.state.engagement.metrics.affiliate).toFixed(2)}</span></h3>
                        </Col>
                        <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                          <InputGroup>
                            <Input id="affiliate" bsSize="16" type="text" value={this.state.engagement.metrics.affiliate} onChange={(e) => this.handleMetric(e)} />
                            {this.state.results && this.state.results.weighted_totals && (this.state.results.weighted_totals.qty > 0) ? (
                              <div className="input-group-append">
                                <ButtonGroup>
                                  <Button id="btn_affiliate" color="danger" onClick={(e) => this.setDefault(e, parseFloat(this.state.results.totals.affiliate_commission/this.state.results.totals.qty))}>{'$' + parseFloat(this.state.results.totals.affiliate_commission/this.state.results.totals.qty).toFixed(2)}</Button>
                                </ButtonGroup>
                              </div>
                            ) : null }
                          </InputGroup>
                        </Col>
                      </Row>

                      {this.state.charts ? (
                        <ChartCard print={this.props.print} chart={this.state.charts[4]} />
                      ) : null}

                      <hr className="bg-light" />

                      <Row className="mt-4">
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                          <h5 className="text-warning">Preview</h5>
                          <InputGroup>
                            <Input id="preview" bsSize="16" type="text" value={this.state.engagement.dates.preview} onChange={(e) => this.handleDate(e)} />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                          <h5 className="text-warning">Closing</h5>
                          <InputGroup>
                            <Input id="closing" bsSize="16" type="text" value={this.state.engagement.dates.closing} onChange={(e) => this.handleDate(e)} />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                          <div className="text-right mt-4">
                            <Button className={this.state.isLoaded ? 'btn-light' : 'btn-danger text-white'} onClick={() => this.save()} disabled={!this.state.isLoaded}>
                              <i className="fa-light fa-save" /> &nbsp;SAVE
                            </Button>
                            
                          </div>
                        </Col>
                      </Row>

                    </div>
                  ) : null }
                </div>
              </div>
            ) : (
              <Row className="mt-4 d-none">
                <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                  <h3 className="text-warning">Weekly Face</h3>
                  <h5><span className="text-white">${comma(parseFloat(this.state.engagement.metrics.weekly_face).toFixed(0))}</span></h5>
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                  <h3 className="text-warning">Per Ticket</h3>
                  <h5><span className="text-white">${parseFloat(this.state.engagement.metrics.ticket_face).toFixed(2)}</span></h5>
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                  <h3 className="text-warning">Markup</h3>
                  <h5><span className="text-white">{parseFloat(this.state.engagement.metrics.markup*100).toFixed(2)}%</span></h5>
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                  <h3 className="text-warning">Access Fee</h3>
                  <h5><span className="text-white">${parseFloat(this.state.engagement.metrics.access).toFixed(2)}</span></h5>
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                  <h3 className="text-warning">Affiliate Fee</h3>
                  <h5><span className="text-white">${parseFloat(this.state.engagement.metrics.affiliate).toFixed(2)}</span></h5>
                </Col>
              </Row>
            )}

        </div>
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisEngagementCard));
