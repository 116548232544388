import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';

import Displays from '../../../components/Displays';

import {
    Row,
    Col
  } from 'reactstrap';

import ForecastVenueCard from './cards/Venue.js';

class WidgetOpsisForecastWeek extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    week: PropTypes.string,
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false
    }

    this._isMounted = false;
    this.loadWeek = this.loadWeek.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadWeek();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'VENUE FORECAST WIDGET')) {
      this.loadForecast();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadWeek() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var url = '/opsis/weeks/' + this.props.week;

      var opts = {
        path: url,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  render() {

    var title = <h5><span className="text-danger">Forecast Week</span> <small className="text-muted">Latest forecast figures</small></h5>;
    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-2">

              {this.state.isLoaded ? (
                <div className="mt-1">

                  {this.state.results && this.state.results.venues && this.state.results.venues.length > 0 ? (
                    <div className="mt-4">

                      <Row>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">Qty</p>
                              <h4 className="text-center"><Displays a={parseInt(this.state.results.week.forecast.qty)} format="comma" /></h4>
                              <p className="fs-mini text-muted text-center">--</p>
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">Face</p>
                              <h4 className="text-center">$<Displays a={parseInt(this.state.results.week.forecast.face)} format="comma" /></h4>
                              <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.week.forecast.face/this.state.results.week.forecast.qty).toFixed(2))}</p>
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">Fees</p>
                              <h4 className="text-center">$<Displays a={parseInt(this.state.results.week.forecast.fees)} format="comma" /></h4>
                              <p className="fs-mini text-muted text-center">{parseFloat((this.state.results.week.forecast.fees/this.state.results.week.forecast.face) * 100).toFixed(1)}%</p>
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">Access</p>
                              <h4 className="text-center">$<Displays a={parseInt(this.state.results.week.forecast.access)} format="comma" /></h4>
                              <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.week.forecast.access/this.state.results.week.forecast.qty).toFixed(2))}</p>
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">Affiliate</p>
                              <h4 className="text-center">$<Displays a={parseInt(this.state.results.week.forecast.affiliate)} format="comma" /></h4>
                              <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.week.forecast.affiliate/this.state.results.week.forecast.qty).toFixed(2))}</p>
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">CC Fee</p>
                              <h4 className="text-center">$<Displays a={parseInt(this.state.results.week.forecast.cc_fees)} format="comma" /></h4>
                              <p className="fs-mini text-muted text-center">{parseFloat((this.state.results.week.forecast.cc_fees/(this.state.results.week.forecast.fees + this.state.results.week.forecast.face)) * 100 ).toFixed(2)}%</p>
                          </Col>
                      </Row>


                      {this.state.results.venues.map((week, i) =>
                        <div key={i}>
                          <ForecastVenueCard print={this.props.print} week={week} />
                        </div>
                      )}
                    
                    </div>
                  ): null}
                  
                </div>
              ): null}

            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisForecastWeek));
