import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';

import VenueCard from './cards/Venue.js';

class WidgetOpsisVenues extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false
    }

    this._isMounted = false;
    this.loadVenues = this.loadVenues.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadVenues();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'HISTORICAL WIDGET')) {
      this.loadVenues();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadVenues() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/opsis/venues',
        type: 'universal'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  render() {

    var title = <h5><span className="text-danger">Forecasted Venues</span> <small className="text-muted">{this.state.isLoaded ? (<span className="pl-2">For the forecast date {this.state.results.venues[0].forecast_date}</span>) : null}</small></h5>;
    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-2">
              
              {this.state.isLoaded ? (
                <div className="mt-1">
                  <hr/>
                  {this.state.results.venues.map((venue, i) =>
                    <div key={i}>
                      <VenueCard print={this.props.print} venue={venue} />
                    </div>
                  )}
                </div>
              ): null}

            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisVenues));
