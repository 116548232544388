import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Nav,
  NavItem,
  Button,
  ButtonGroup,
  Row,
  Col
} from 'reactstrap';

import Widget from '../../components/Widget';
import WidgetBwExperiment from '../../widgets/bw/Experiment';
import WidgetBwConversions from '../../widgets/bw/Conversions';
import WidgetTestDetail from '../../widgets/bw/TestDetail';

import isScreen from '../../core/screenHelper';

class Testing extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      print: true
    };

    this._isMounted = false;

    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.setPrint = this.setPrint.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setTabs();
  };

  setTabs(){

      var tabSelected = -1;
      var tabs = [
        {
          id: 0,
          icon: 'fa-scale-balanced',
          name: 'Summary'
        },
        {
          id: 1,
          icon: 'fa-calendar',
          name: 'Years'
        },
        {
          id: 2,
          icon: 'fa-file-excel',
          name: 'Detail'
        }
      ];
      
      this.setState({
        tabs: tabs,
        tabSelected: tabSelected
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  setPrint() { 
    this.setState({
      print: !this.state.print
    });
  }

  render() {
  
    return (
      <div>

        <div className="menuContainer lg">
          <Nav className="gutter-top-fixed-2">
            <NavItem className="nopad-bottom nomargin-bottom">
              
                <h5 className="text-danger">Experiments</h5>

                {this.state.tabs ? (
                <ButtonGroup>
                  {this.state.tabs.map((tab, i) =>
                    <Button key={i}
                      color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                      active={this.state.tabSelected === i}
                    ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                    <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                  )}
                </ButtonGroup>
                ) : null}
            </NavItem>
          </Nav>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === 0 ? (

            <div>

              <Widget className="d-none mb-1 bg-gray">
                <Row>
                  <Col xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p className="fs-mini font-weight-bold">Options</p>
                  </Col>
                  <Col xs={4} sm={3} md={3} lg={3} xl={3} className="text-center">
                    <p className="fs-mini mb-0 pb-0">Format</p>
                    <button className="header-link" onClick={() => this.setPrint()}>{this.state.print ? (<span>Print</span>) : ((<span>Display</span>))}</button>
                  </Col>
                </Row>
              </Widget>

              <div className="gutter-top-fixed-2">
                {this.props.filter.limit.experiments && (this.props.filter.limit.experiments.length > 0) ? (
                  <WidgetBwExperiment print={this.state.print} experiment={this.props.filter.limit.experiments[0]} />
                ) : (
                  <p>Select experiment from filter.</p>
                )}
              </div>

            </div>
          ): null}

          {this.state.tabSelected === 1 ? (

            <div>
              <WidgetBwConversions print={this.state.print} experiments={true} />
            </div>

          ) : null}

          {this.state.tabSelected === 2 ? (

              <div>
                <WidgetTestDetail print={this.state.print} experiment={this.props.filter.limit.experiments[0]} />
              </div>

          ) : null}
        
        </div>

      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Testing));
