import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';

import Widget from '../../../components/Widget/Widget.js';
import Loading from '../../../components/Loading/Loading.js';
import Filter from '../../universal/Filter';
import { filterUpdated, surl, isWeeks } from '../../../core/utils.js';
import { Row, Col, Button, Table } from 'reactstrap';

class WidgetDownloadsExcel extends React.Component {

  static propTypes = {
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false,
    }

    this._isMounted = false;
    this.setReports = this.setReports.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setReports();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'FILTER COMPONENT UPDATED')) {
      this.setReports();
    }
  }

  setReports() {

    let reports = [];

      //FLASH
      let flash = {
        title: 'Weekly Flash',
        description: 'Weekly report emailed to management on Monday mornings.',
        type: 'Excel',
        last_updated: DateTime.fromISO('2024-08-22').toLocaleString(DateTime.DATE_FULL),
        requires: '',
        status: true,
        status_message: 'Ready',
        path: surl({path: '/v2/excel/flash', filter: 'universal'})
      };
      reports.push(flash);

      if(!isWeeks(this.props.filter)) {
        flash.status = false;
        flash.status_message = 'Dates range must be single week'
      }


    //MARKET SHARE REPORT
    let share = {
      title: 'Market Share Report',
      description: 'Compares Broadway.com sales to the industry, using Broadway League grosses.',
      type: 'Excel',
      last_updated: DateTime.fromISO('2024-08-08').toLocaleString(DateTime.DATE_FULL),
      requires: 'Dates must begin on a Monday and end on a Sunday',
      status: isWeeks(this.props.filter),
      status_message: 'Ready',
      path: surl({path: '/v2/excel/share', filter: 'universal'})
    };

    if(!isWeeks(this.props.filter)) {
      share.status_message = 'Dates range must be weekendings'
    }

    if(this.props.filter.limit.engagements && (this.props.filter.limit.engagements.length !== 0)) {
      share.status = false;
      share.status_message = 'Must not filter shows'
    }

    reports.push(share);

    //TRANSACTIONS
    let transactions = {
      title: 'Transaction Detail',
      description: 'Complete transactions that match filter criteria. Used for Affiliate reports and Known detail.',
      type: 'Excel',
      last_updated: DateTime.fromISO('2024-08-11').toLocaleString(DateTime.DATE_FULL),
      requires: '',
      status: true,
      status_message: '',
      path: surl({path: '/v2/excel/transactions', filter: 'universal'})
    };
    reports.push(transactions);


    //SHOW REPORT
    let show = {
      title: 'Show Spotlight',
      description: 'Metrics around a single show, to potentially be shared with external parties.',
      type: 'Excel',
      last_updated: DateTime.fromISO('2024-08-08').toLocaleString(DateTime.DATE_FULL),
      requires: 'Dates must begin on a Monday and end on a Sunday',
      status: false,
      status_message: 'Filter must limit to single show',
      path: surl({path: '/v2/excel/share', filter: 'universal'})
    };

    if(this.props.filter.limit.engagements && (this.props.filter.limit.engagements.length === 1)) {
      show.status = true;
      show.status_message = 'Ready'
    }

    //reports.push(show);





    this.setState({
      ...this.state,
      reports: reports,
      isLoaded: true
    });
  };

  render() {

    var title = <h5><span className="text-danger">Downloads</span> <small className="text-muted">Adjust filter and select report</small></h5>;
    return (

      <div>

        <Row className="gutter-top-fixed-2 gutter-bottom-fixed-2">
            <Col xs={12} sm={4} md={4} lg={4}>
              <Filter />
            </Col>
            <Col xs={12} sm={8} md={8} lg={8}>
              <Widget title={title}>

                <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

                {this.state.isLoaded ? (
                  <div className="gutter-top-fixed-1">

                    <Table className="fs-mini nopad-bottom nomargin-bottom border">
                      <thead className="bg-gray">
                        <tr>
                            <th width="35%"><h6 className="gutter-top-fixed-1">Report</h6></th>
                            <th className="text-left" width="25%"><h6 className="gutter-top-fixed-1">Properties</h6></th>
                            <th className="text-left"width="25%"><h6 className="gutter-top-fixed-1">Run Status</h6></th>
                            <th className="text-center" width="15%">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody> 

                      {this.state.reports.map((report, i) =>
                        <tr key={i}>
                          <td>
                            <h5 className="gutter-top-fixed-1">{report.title}</h5>
                            <p>{report.description}</p>
                          </td>
                          <td className="text-left">
                            <p className="gutter-top-fixed-1"><span className="fw-bold">Type:</span> {report.type}</p>
                            <p><span className="fw-bold">Last Updated:</span> {report.last_updated}</p>
                          </td>
                          <td className="text-left">
                            <p className={`gutter-top-fixed-2 ${report.status ? 'text-success' : 'text-danger'}`}>{report.status_message}</p>
                          </td>
                          <td className="text-center">
                            <div className="gutter-top-fixed-1">
                              <a className="w-100" href={report.path} target="_blank" rel="noopener noreferrer">
                                <Button className="btn-default w-100" disabled={!report.status}>
                                  <h6 className="gutter-top-fixed-1"><i className="fa-light fa-download" /> Download</h6>
                                </Button>
                              </a>
                            </div>
                          </td>
                        </tr>
                      )}

                        
                      </tbody>
                    </Table>

                  </div>
                ) : null}

              </Widget>
          </Col>
        </Row>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetDownloadsExcel));
