import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser } from '../../../core/utils.js';
import isScreen from '../../../core/screenHelper';

import {
  Nav,
  NavItem,
  Button,
  ButtonGroup,
} from 'reactstrap';

import WidgetOpsisForecastWeek from '../../../widgets/opsis/ForecastWeek';

class OpsisWeek extends React.Component {

  constructor(props) {
    super(props);

    var tabs = [
      {
        id: 0,
        icon: 'fa-crystal-ball',
        name: 'Forecast'
      }
    ];

    this.state = {
      isLoaded: false,
      tabSelected: 0,
      tabs: tabs
    };

    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  setTabs = () => { 
    getUser(function(err, user) {
      if(err) {
        console.log('error retreiving user');
      } else {
        var tabs = this.state.tabs;
        this.setState({
          tabs: tabs
        });
      }
    }.bind(this));
  }

  selectOpsis = (e) => {
    e.preventDefault();
    this.props.history.push('/app/opsis/');
  }

  render() {

    return (
      
      <div>
        <div className="menuContainer lg">

          <Nav className="mt-4">
            <NavItem className="nopad-bottom nomargin-bottom">
                <h5 className="text-danger">Opsis : {this.props.match.params.id}</h5>
                {this.state.tabs ? (
                <ButtonGroup>
                  <Button color="default" className="mr-xs" onClick={((e) => this.selectOpsis(e))}><i className="fa fa-circle-arrow-left fa-light fa-lg text-danger" /></Button>
                 
                  {this.state.tabs.map((tab, i) =>
                    <Button key={i}
                      color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                      active={this.state.tabSelected === i}
                    ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                    <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                  )}
                </ButtonGroup>
                ) : null}
            </NavItem>
          </Nav>

        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>
          {this.state.tabSelected === 0 ? (
            <WidgetOpsisForecastWeek week={this.props.match.params.id} print={false} />
          ): null}
        </div>

      </div>
    );

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(OpsisWeek));
