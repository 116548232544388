import moment from 'moment';

export const loadState = (local) => {
    try {

      local = local || false;

      const serializedState = localStorage.getItem('sh_state');
  
      if (serializedState === null) {
        return undefined;
      } else {
        var parsed = JSON.parse(serializedState);
        if(local) {
          return parsed;
        } else {
          if(!parsed.filter || !parsed.filter.updated) {
              return undefined;
          } else {
          //return parsed;
            if(moment(parsed.filter.updated, 'YYYY-MM-DD HH:mm:ss').isBefore(moment().add(-30, 'day'))) {
              console.log('local storage expired');
              return undefined;
            } else {
              console.log(moment(parsed.filter.updated, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') + ' < ' + moment().add(-3, 'day').format('YYYY-MM-DD HH:mm:ss'));
              return parsed;
            }
          }
        }
      }
    } catch (err) {
      return undefined;
    }
  };
  
  export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('sh_state', serializedState);
    } catch (err) {
      // die
    }
  };