import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';

import moment from 'moment';
import Displays from '../../../components/Displays';
import {
  Row,
  Col,
  Card,
  Table
} from 'reactstrap';

import ForecastWeekCard from './cards/Week.js';

class WidgetOpsisForecastWeeks extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    venue_id: PropTypes.number,
    forecast_date: PropTypes.string,
    summary: PropTypes.bool,
  };

  static defaultProps = {
    print: false,
    summary: false
  };

  constructor(props) {
    super(props);

    var isvenue = false;
    if(this.props.venue_id) {
      isvenue = true;
    }

    if(this.props.forecast) {
      this.state = {
        isLoaded: true,
        isError: false,
        venue: isvenue,
        results: this.props.forecast
      }
    } else {
      this.state = {
        isLoaded: false,
        isError: false,
        venue: isvenue,
      }
    }


    this._isMounted = false;
    this.loadForecast = this.loadForecast.bind(this);
    this.fire = this.fire.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if(!this.state.isLoaded) {
      this.loadForecast();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'VENUE FORECAST WIDGET')) {
      this.loadForecast();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadForecast() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var url = '/v2/opsis/forecast';

      if(this.props.venue_id) {
        url += '?venue_id=' + this.props.venue_id;
      }

      var opts = {
        path: url,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  fire = () => { 

    this.setState({
      isLoaded: false
    }, function() {
      
      var url = '/v2/opsis/fire'
      if(this.props.venue_id) {
        url += '?venue_id=' + this.props.venue_id;
      }
  
      var opts = {
        path: url,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          window.location.reload();
        }
      }.bind(this));
    });
  }

  render() {

    var title = <h5><span className="text-danger">{this.props.venue_id ? ('Venue '): null}Forecast</span> <small className="text-muted"></small></h5>;
    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />


            {this.state.isLoaded ? (
              <div className="mt-1">

                {this.props.summary ? (
                  <>
                  {this.state.results.years[0].forecast.qty > 0 ? (

                    <Row className="m-0 p-0">
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="gutter-top-fixed-1 bg-light border">
                          <Table className="fs-mini nopad-bottom nomargin-bottom border">
                            <thead>
                              <tr>
                                  <th width="25%">Metric</th>
                                  <th className="text-center"width="25%">2023-2024</th>
                                  <th className="text-center" width="25%">2024-2025</th>
                                  <th className="text-center" width="25%">+/-</th>
                              </tr>
                            </thead>
                            <tbody> 
                              <tr>
                                <td>
                                  <h6>Ticket Qty</h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={parseInt(this.state.results.years[1].forecast.qty)} format="comma" /></h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={this.state.results.years[0].forecast.qty} format="comma" /></h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={parseInt(this.state.results.years[0].forecast.qty)} b={parseInt(this.state.results.years[1].forecast.qty)} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Avg Price</h6>
                                </td>
                                <td className="text-center">
                                  <h6>${parseFloat(this.state.results.years[1].forecast.face/this.state.results.years[1].forecast.qty).toFixed(2)}</h6>
                                </td>
                                <td className="text-center">
                                  <h6>${parseFloat(this.state.results.years[0].forecast.face/this.state.results.years[0].forecast.qty).toFixed(2)}</h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={parseFloat(this.state.results.years[0].forecast.face/this.state.results.years[0].forecast.qty)} b={parseFloat(this.state.results.years[1].forecast.face/this.state.results.years[1].forecast.qty)} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Ticket Face</h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={parseInt(this.state.results.years[1].forecast.face)} format="comma" /></h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={this.state.results.years[0].forecast.face} format="comma" /></h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={parseInt(this.state.results.years[0].forecast.face)} b={parseInt(this.state.results.years[1].forecast.face)} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Avg Margin</h6>
                                </td>
                                <td className="text-center">
                                  <h6>{parseFloat((this.state.results.years[1].forecast.fees/this.state.results.years[1].forecast.face)*100).toFixed(2)}%</h6>
                                </td>
                                <td className="text-center">
                                  <h6>{parseFloat((this.state.results.years[0].forecast.fees/this.state.results.years[0].forecast.face)*100).toFixed(2)}%</h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={parseFloat(this.state.results.years[0].forecast.fees/this.state.results.years[0].forecast.face)} b={parseFloat(this.state.results.years[1].forecast.fees/this.state.results.years[1].forecast.face)} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6><strong>Ticket Fees</strong></h6>
                                </td>
                                <td className="text-center">
                                  <h6><strong>$<Displays a={parseInt(this.state.results.years[1].forecast.fees)} format="comma" /></strong></h6>
                                </td>
                                <td className="text-center">
                                  <h6><strong>$<Displays a={this.state.results.years[0].forecast.fees} format="comma" /></strong></h6>
                                </td>
                                <td className="text-center">
                                  <h6><strong><Displays a={parseInt(this.state.results.years[0].forecast.fees)} b={parseInt(this.state.results.years[1].forecast.fees)} format="percent" /></strong></h6>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className="gutter-top-fixed-1 bg-light border">
                          <Table className="fs-mini nopad-bottom nomargin-bottom border">
                            <thead>
                              <tr>
                                  <th width="25%">Metric</th>
                                  <th className="text-center"width="25%">2023-2024</th>
                                  <th className="text-center" width="25%">2024-2025</th>
                                  <th className="text-center" width="25%">+/-</th>
                              </tr>
                            </thead>
                            <tbody> 
                              <tr>
                                <td>
                                  <h6>Access Per</h6>
                                </td>
                                <td className="text-center">
                                  <h6>${parseFloat(this.state.results.years[1].forecast.access/this.state.results.years[1].forecast.qty).toFixed(2)}</h6>
                                </td>
                                <td className="text-center">
                                  <h6>${parseFloat(this.state.results.years[0].forecast.access/this.state.results.years[0].forecast.qty).toFixed(2)}</h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={parseFloat(this.state.results.years[0].forecast.access/this.state.results.years[0].forecast.qty)} b={parseFloat(this.state.results.years[1].forecast.access/this.state.results.years[1].forecast.qty)} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Access Total</h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={parseInt(this.state.results.years[1].forecast.access)} format="comma" /></h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={this.state.results.years[0].forecast.access} format="comma" /></h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={parseInt(this.state.results.years[0].forecast.access)} b={parseInt(this.state.results.years[1].forecast.access)} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Affiliate Per</h6>
                                </td>
                                <td className="text-center">
                                  <h6>${parseFloat(this.state.results.years[1].forecast.affiliate/this.state.results.years[1].forecast.qty).toFixed(2)}</h6>
                                </td>
                                <td className="text-center">
                                  <h6>${parseFloat(this.state.results.years[0].forecast.affiliate/this.state.results.years[0].forecast.qty).toFixed(2)}</h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={parseFloat(this.state.results.years[0].forecast.affiliate/this.state.results.years[0].forecast.qty)} b={parseFloat(this.state.results.years[1].forecast.affiliate/this.state.results.years[1].forecast.qty)} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Affiliate Total</h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={parseInt(this.state.results.years[1].forecast.affiliate)} format="comma" /></h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={this.state.results.years[0].forecast.affiliate} format="comma" /></h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={parseInt(this.state.results.years[0].forecast.affiliate)} b={parseInt(this.state.results.years[1].forecast.affiliate)} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Credit Cards</h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={parseInt(this.state.results.years[1].forecast.cc_fees)} format="comma" /></h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<Displays a={this.state.results.years[0].forecast.cc_fees} format="comma" /></h6>
                                </td>
                                <td className="text-center">
                                  <h6><Displays a={parseInt(this.state.results.years[0].forecast.cc_fees)} b={parseInt(this.state.results.years[1].forecast.cc_fees)} format="percent" /></h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6><strong>Expenses</strong></h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<strong><Displays a={parseInt(this.state.results.years[1].forecast.access+this.state.results.years[1].forecast.affiliate+this.state.results.years[1].forecast.cc_fees)} format="comma" /></strong></h6>
                                </td>
                                <td className="text-center">
                                  <h6>$<strong><Displays a={this.state.results.years[0].forecast.access+this.state.results.years[0].forecast.affiliate+this.state.results.years[0].forecast.cc_fees} format="comma" /></strong></h6>
                                </td>
                                <td className="text-center">
                                  <h6><strong><Displays a={parseInt(this.state.results.years[0].forecast.access+this.state.results.years[0].forecast.affiliate+this.state.results.years[0].forecast.cc_fees)} b={parseInt(this.state.results.years[1].forecast.access+this.state.results.years[1].forecast.affiliate+this.state.results.years[1].forecast.cc_fees)} format="percent" /></strong></h6>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>

                        <div className="gutter-top-fixed-1 bg-light border">
                          <Table className="fs-mini nopad-bottom nomargin-bottom border">
                            <tbody> 
                              <tr>
                                <td width="25%">
                                  <h6><strong>Net</strong></h6>
                                </td>
                                <td width="25%" className="text-center">
                                  <h6>$<strong><Displays a={parseInt(this.state.results.years[1].forecast.net)} format="comma" /></strong></h6>
                                </td>
                                <td width="25%" className="text-center">
                                  <h6>$<strong><Displays a={this.state.results.years[0].forecast.net} format="comma" /></strong></h6>
                                </td>
                                <td width="25%" className="text-center">
                                  <h6><strong><Displays a={parseInt(this.state.results.years[0].forecast.net)} b={parseInt(this.state.results.years[1].forecast.net)} format="percent" /></strong></h6>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <div>Currently, no projected sales for 2024-2025</div>
                  )}
                  </>
                ) : (
                  <>
                    {this.state.results && this.state.results.weeks && this.state.results.weeks.length > 0 ? (
                      <div className="mt-4">

                        {!this.props.venue_id ? (
                          <Row className="">
                            <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2 text-center">
                              <Card className="bg-light gutter-bottom-fixed-4">
                                <p className="gutter-top-fixed-1">Tickets</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.forecast.net)} format="comma" /></h4>
                                <p><Displays a={parseInt(this.state.results.forecast_total.forecast.net)} b={parseInt(this.state.results.forecast_total.compare.forecast.net)} format="percent" /></p>
                              </Card>
                            </Col>
                            <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2 text-center">
                              <Card className="bg-light gutter-bottom-fixed-4">
                                <p className="gutter-top-fixed-1">Ad Sales</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.static.adsales)} format="comma" /></h4>
                                <p><Displays a={parseInt(this.state.results.forecast_total.static.adsales)} b={parseInt(this.state.results.forecast_total.compare.static.adsales)} format="percent" /></p>
                              </Card>
                            </Col>
                            <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2 text-center">
                              <Card className="bg-light gutter-bottom-fixed-4">
                                <p className="gutter-top-fixed-1">Marketing</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.static.kenshoo)} format="comma" /></h4>
                                <p><Displays a={parseInt(this.state.results.forecast_total.static.kenshoo)} b={parseInt(this.state.results.forecast_total.compare.static.kenshoo)} format="percent" /></p>
                              </Card>
                            </Col>
                            <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2 text-center">
                              <Card className="bg-light gutter-bottom-fixed-4">
                                <p className="gutter-top-fixed-1">Gross Profit</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.net)} format="comma" /></h4>
                                <p><Displays a={parseInt(this.state.results.forecast_total.net)} b={parseInt(this.state.results.forecast_total.compare.net)} format="percent" /></p>
                              </Card>
                            </Col>
                          </Row>
                        ) : (
                          <Row className="">
                            <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2 text-center">
                              <Card className="bg-light gutter-bottom-fixed-4">
                                <p className="gutter-top-fixed-1">Service Fees</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.forecast.fees)} format="comma" /></h4>
                                <p><Displays a={parseInt(this.state.results.forecast_total.forecast.fees)} b={parseInt(this.state.results.forecast_total.compare.forecast.fees)} format="percent" /></p>
                              </Card>
                            </Col>
                            <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2 text-center">
                              <Card className="bg-light gutter-bottom-fixed-4">
                                <p className="gutter-top-fixed-1">Access Fees</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.forecast.access)} format="comma" /></h4>
                                <p><Displays a={parseInt(this.state.results.forecast_total.forecast.access)} b={parseInt(this.state.results.forecast_total.compare.forecast.access)} format="percent" /></p>
                              </Card>
                            </Col>
                            <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2 text-center">
                              <Card className="bg-light gutter-bottom-fixed-4">
                                <p className="gutter-top-fixed-1">Affiliate Fees</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.forecast.affiliate)} format="comma" /></h4>
                                <p><Displays a={parseInt(this.state.results.forecast_total.forecast.affiliate)} b={parseInt(this.state.results.forecast_total.compare.forecast.affiliate)} format="percent" /></p>
                              </Card>
                            </Col>
                            <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2 text-center">
                              <Card className="bg-light gutter-bottom-fixed-4">
                                <p className="gutter-top-fixed-1">Net</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.forecast.net)} format="comma" /></h4>
                                <p><Displays a={parseInt(this.state.results.forecast_total.forecast.net)} b={parseInt(this.state.results.forecast_total.compare.forecast.net)} format="percent" /></p>
                              </Card>
                            </Col>
                          </Row>
                        )}

                        <Card className="bg-light gutter-bottom-fixed-4 d-none">

                          <h5 className="gutter-left-fixed-2 gutter-top-fixed-2">Forecast Totals</h5>

                          <Row>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">Qty</p>
                                <h4 className="text-center"><Displays a={parseInt(this.state.results.forecast_total.forecast.qty)} format="comma" /></h4>
                                <p className="fs-mini text-muted text-center">--</p>
                                {this.state.results.forecast_total.compare ? (
                                  <div>
                                    <h6 className="text-center text-warning"><Displays a={parseInt(this.state.results.forecast_total.compare.forecast.qty)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">--</p>
                                  </div>
                                ) : null}
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <div>
                                    <h6 className="text-center text-danger"><Displays a={parseInt(this.state.results.forecast_total.actuals.transactions.qty)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">--</p>
                                  </div>
                                ) : null}
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">Face</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.forecast.face)} format="comma" /></h4>
                                <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.forecast_total.forecast.face/this.state.results.forecast_total.forecast.qty).toFixed(2))}</p>
                                {this.state.results.forecast_total.compare ? (
                                  <div>
                                    <h6 className="text-center text-warning">$<Displays a={parseInt(this.state.results.forecast_total.compare.forecast.face)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.forecast_total.compare.forecast.face/this.state.results.forecast_total.compare.forecast.qty).toFixed(2))}</p>
                                  </div>
                                ) : null}
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <div>
                                    <h6 className="text-center text-danger">$<Displays a={parseInt(this.state.results.forecast_total.actuals.transactions.face)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.forecast_total.actuals.transactions.face/this.state.results.forecast_total.actuals.transactions.qty).toFixed(2))}</p>
                                  </div>
                                ) : null}
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">Fees</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.forecast.fees)} format="comma" /></h4>
                                <p className="fs-mini text-muted text-center">{parseFloat((this.state.results.forecast_total.forecast.fees/this.state.results.forecast_total.forecast.face) * 100).toFixed(1)}%</p>
                                {this.state.results.forecast_total.compare ? (
                                  <div>
                                    <h6 className="text-center text-warning">$<Displays a={parseInt(this.state.results.forecast_total.compare.forecast.fees)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">{parseFloat((this.state.results.forecast_total.compare.forecast.fees/this.state.results.forecast_total.compare.forecast.face) * 100).toFixed(1)}%</p>
                                  </div>
                                ) : null}
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <div>
                                    <h6 className="text-center text-danger">$<Displays a={parseInt(this.state.results.forecast_total.actuals.transactions.fees)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">{parseFloat((this.state.results.forecast_total.actuals.transactions.fees/this.state.results.forecast_total.actuals.transactions.face) * 100).toFixed(1)}%</p>
                                  </div>
                                ) : null}
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">Access</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.forecast.access)} format="comma" /></h4>
                                <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.forecast_total.forecast.access/this.state.results.forecast_total.forecast.qty).toFixed(2))}</p>
                                {this.state.results.forecast_total.compare ? (
                                  <div>
                                    <h6 className="text-center text-warning">$<Displays a={parseInt(this.state.results.forecast_total.compare.forecast.access)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.forecast_total.compare.forecast.access/this.state.results.forecast_total.compare.forecast.qty).toFixed(2))}</p>
                                  </div>
                                ) : null}
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <div>
                                    <h6 className="text-center text-danger">$<Displays a={parseInt(this.state.results.forecast_total.actuals.transactions.access)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.forecast_total.actuals.transactions.access/this.state.results.forecast_total.actuals.transactions.qty).toFixed(2))}</p>
                                  </div>
                                ) : null}
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">Affiliate</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.forecast.affiliate)} format="comma" /></h4>
                                <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.forecast_total.forecast.affiliate/this.state.results.forecast_total.forecast.qty).toFixed(2))}</p>
                                {this.state.results.forecast_total.compare ? (
                                  <div>
                                    <h6 className="text-center text-warning">$<Displays a={parseInt(this.state.results.forecast_total.compare.forecast.affiliate)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.forecast_total.compare.forecast.affiliate/this.state.results.forecast_total.compare.forecast.qty).toFixed(2))}</p>
                                  </div>
                                ) : null}
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <div>
                                    <h6 className="text-center text-danger">$<Displays a={parseInt(this.state.results.forecast_total.actuals.transactions.affiliate)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.state.results.forecast_total.actuals.transactions.affiliate/this.state.results.forecast_total.actuals.transactions.qty).toFixed(2))}</p>
                                  </div>
                                ) : null}
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">CC Fee</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.forecast.cc_fees)} format="comma" /></h4>
                                <p className="fs-mini text-muted text-center">{parseFloat((this.state.results.forecast_total.forecast.cc_fees/(this.state.results.forecast_total.forecast.fees + this.state.results.forecast_total.forecast.face)) * 100 ).toFixed(2)}%</p>
                                {this.state.results.forecast_total.compare ? (
                                  <div>
                                    <h6 className="text-center text-warning">$<Displays a={parseInt(this.state.results.forecast_total.compare.forecast.cc_fees)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">{parseFloat((this.state.results.forecast_total.compare.forecast.cc_fees/(this.state.results.forecast_total.compare.forecast.fees + this.state.results.forecast_total.compare.forecast.face)) * 100 ).toFixed(2)}%</p>
                                  </div>
                                ) : null}
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <div>
                                    <h6 className="text-center text-danger">$<Displays a={parseInt(this.state.results.forecast_total.actuals.transactions.cc_fees)} format="comma" /></h6>
                                    <p className="fs-mini text-muted text-center">{parseFloat((this.state.results.forecast_total.actuals.transactions.cc_fees/(this.state.results.forecast_total.actuals.transactions.fees + this.state.results.forecast_total.actuals.transactions.face)) * 100 ).toFixed(2)}%</p>
                                  </div>
                                ) : null}
                            </Col>
                          </Row>
                          <hr/>
                          <Row>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">Ticketing</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.forecast.net)} format="comma" /></h4>
                                {this.state.results.forecast_total.compare ? (
                                  <p className="text-center text-warning">$<Displays a={parseInt(this.state.results.forecast_total.compare.forecast.net)} format="comma" /></p>
                                ) : null}
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <p className="text-center text-danger">$<Displays a={parseInt(this.state.results.forecast_total.actuals.transactions.net)} format="comma" /></p>
                                ) : null}
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">Ad Sales</p>
                                <h4 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.static.adsales)} format="comma" /></h4>
                                {this.state.results.forecast_total.compare ? (
                                  <p className="text-center text-warning">$<Displays a={parseInt(this.state.results.forecast_total.compare.static.adsales)} format="comma" /></p>
                                ) : null}
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <p className="text-center text-danger">$<Displays a={parseInt(this.state.results.forecast_total.actuals.static.adsales)} format="comma" /></p>
                                ) : null}
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">Search</p>
                                <h4 className="text-center">$-<Displays a={parseInt(this.state.results.forecast_total.static.kenshoo)} format="comma" /></h4>
                                {this.state.results.forecast_total.compare ? (
                                  <p className="text-center text-warning">$-<Displays a={parseInt(this.state.results.forecast_total.compare.static.kenshoo)} format="comma" /></p>
                                ) : null}
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <p className="text-center text-danger">$<Displays a={parseInt(this.state.results.forecast_total.actuals.static.kenshoo)} format="comma" /></p>
                                ) : null}
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">Payroll</p>
                                <h4 className="text-center">$-<Displays a={parseInt(this.state.results.forecast_total.static.payroll)} format="comma" /></h4>
                                {this.state.results.forecast_total.compare ? (
                                  <p className="text-center text-warning">$-<Displays a={parseInt(this.state.results.forecast_total.compare.static.payroll)} format="comma" /></p>
                                ) : null}
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <p className="text-center text-danger">$<Displays a={parseInt(this.state.results.forecast_total.compare.static.payroll)} format="comma" /></p>
                                ) : null}
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">Fixed</p>
                                <h4 className="text-center">$-<Displays a={parseInt(this.state.results.forecast_total.static.fixed)} format="comma" /></h4>
                                {this.state.results.forecast_total.compare ? (
                                  <p className="text-center text-warning">$-<Displays a={parseInt(this.state.results.forecast_total.compare.static.fixed)} format="comma" /></p>
                                ) : null}
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <p className="text-center text-danger">$<Displays a={parseInt(this.state.results.forecast_total.compare.static.fixed)} format="comma" /></p>
                                ) : null}
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                <p className="fs-mini text-muted text-center">Net</p>
                                <h2 className="text-center">$<Displays a={parseInt(this.state.results.forecast_total.net)} format="comma" /></h2>
                                {this.state.results.forecast_total.compare ? (
                                  <h6 className="text-center text-warning">$<Displays a={parseInt(this.state.results.forecast_total.compare.net)} format="comma" /></h6>
                                ) : null }
                                {this.state.results.forecast_total.actuals && this.state.results.forecast_total.actuals.transactions && moment(this.state.results.forecast_total.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                  <p className="text-center text-danger">$<Displays a={parseInt(this.state.results.forecast_total.actuals.transactions.net+this.state.results.forecast_total.actuals.static.adsales-this.state.results.forecast_total.actuals.static.kenshoo-this.state.results.forecast_total.compare.static.payroll-this.state.results.forecast_total.compare.static.fixed)} format="comma" /></p>
                                ) : null}
                            </Col>
                          </Row>
                        </Card>

                        <Row className="">
                          <Col xs={12} sm={5} md={5} lg={5} xl={5} className="gutter-top-fixed-2">
                            <br/>WEEKENDINGS
                          </Col>
                          <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
                            PRIOR<br/>FORECAST
                          </Col>
                          <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
                            CURRENT<br/>FORECAST
                          </Col>
                          <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
                            <br/>+/-
                          </Col>
                        </Row>

                        <hr/>

                        {this.state.results.weeks.map((week, i) =>
                          moment(week.weekending, 'YYYY-MM-DD').isSameOrAfter(moment(this.state.results.forecast_dates.compare.date, 'YYYY-MM-DD').add(8,'days')) ? (
                            <div key={i}>
                              <ForecastWeekCard print={this.props.print} week={week} venue={this.state.venue} />
                            </div>
                          ) : null
                        )}
                      
                      </div>
                    ): (
                      <div className="mt-4 mb-4 text-center">
                        No forecast weeks exist yet
                      </div>
                    )}
                  </>
                )}

              
              </div>
            ): null}


        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisForecastWeeks));
