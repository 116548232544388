import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Displays from '../../../../components/Displays';

import {
    Row,
    Col
  } from 'reactstrap';

import isScreen from '../../../../core/screenHelper';

class WidgetOpsisForecastVenueCard extends React.Component {

  static propTypes = {
    week: PropTypes.object,
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false,
  };

  constructor(props) {
    super(props);

    this._isMounted = false;
    
    var show_count = 12;
    var show_cols = [4,4,4,4,4];
    if(!this.props.print) {
      if((isScreen('xs') || isScreen('sm'))) {
        show_count = 3
        show_cols = [4,4,4,4,4];
      } else {
        if(isScreen('md')) {
          show_count = 6
          show_cols = [4,4,4,4,4];
        }
      }
    }

    this.state = {
      show_count: show_count,
      show_cols: show_cols,
      chart: {
        data: []
      }
    };
  }

  componentDidMount() {

  }

  render() {

    return (
      <div className="card gutter-bottom-fixed-4">
          <div className="card-body">
            {this.state ? (

                <div>
                    <h5>{this.props.week.venue.name}</h5>

                    <div className="gutter-top-fixed-2">

                        <Row>
                            <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                                {this.props.week.engagement && this.props.week.engagement.show && this.props.week.engagement.show.name ? (
                                  <p className="fs-mini text-muted text-center">{this.props.week.engagement.show.name}</p>
                                ) : (
                                  <p className="fs-mini text-muted text-center">Dark</p>
                                )}
                                <h4 className="text-center">$<Displays a={parseInt(this.props.week.forecast.face)} format="comma" /></h4>
                                <p className="fs-mini text-muted text-center">--</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            ) : null }
          </div>
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisForecastVenueCard));
