import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ResponsiveContainer, Line, LineChart, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import moment from 'moment';
import { nFormatter } from '../../../../core/utils';

import isScreen from '../../../../core/screenHelper';

class WidgetOpsisChartCard extends React.Component {

    static propTypes = {
        engagement: PropTypes.object,
        print: PropTypes.bool,
        width: PropTypes.number,
        height: PropTypes.number,
        chartAspectWidth: PropTypes.number,
        chartAspectHeight: PropTypes.number,
        chart: PropTypes.object
      };
    
      static defaultProps = {
        print: false,
        width: 1050,
        height: 400,
        chartAspectWidth: 6,
        chartAspectHeight: 1
      };

      constructor(props) {
        super(props);
    
        this.state = {
          isLoaded: false,
          chartAspectWidth: this.props.chartAspectWidth,
          chartAspectHeight: this.props.chartAspectHeight
        };

        this._isMounted = false;
        this.setChartAspect = this.setChartAspect.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.dataFormat = this.dataFormat.bind(this);
      }

      componentDidMount() {
        this.setChartAspect();
        window.addEventListener('resize', this.handleResize.bind(this));
      }

      setChartAspect() {
        if((isScreen('xs') || isScreen('sm'))) {
          this.setState({
            chartAspectWidth: 2,
            chartAspectHeight: 1
          });
        } else {
          this.setState({
            chartAspectWidth: this.props.chartAspectWidth,
            chartAspectHeight: this.props.chartAspectHeight
          });
        }
      }
    
      handleResize() {
        if(this._isMounted) {
          this.setChartAspect();
        }
      }

      dataFormat(x) {

        var ret = '';
        if(this.props.chart.prefix) {
          ret += this.props.chart.prefix;
        }
        ret += parseFloat(x).toFixed(this.props.chart.decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if(this.props.chart.suffix) {
          ret += this.props.chart.suffix
        }
        return ret

      };
    
      dateFormat(x) {
        return moment(x).format('M/DD');
      };
    
      areaLabelFormat(x) {
        return x
      };

      render() {

        return (
          <div>

            {this.props.chart && this.props.chart.data && this.props.chart.data.length > 0 ? (
                <div className="gutter-top-fixed-3">
                    {this.props.print ? (
                        <LineChart data={this.props.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                            <defs>
                                <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                                </linearGradient>
                                <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                                </linearGradient>
                            </defs>
                            <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}}/>
                            <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[dataMin => this.props.chart.properties.min, dataMax => (dataMax * this.props.chart.properties.max)]} />

                            {this.props.chart.data[0].year1 ? (
                                <Line name={this.props.chart.data[0].year1_label} type="monotone" dataKey="year1" stroke="#82ca9d" strokeWidth={2} label={<CustomizedLabel1 />} />
                            ) : null }

                        </LineChart>
                    ) : (
                        <ResponsiveContainer width='100%' aspect={this.props.chartAspectWidth/this.props.chartAspectHeight} >
                            <LineChart data={this.props.chart.data} margin={{top: 0, right: 0, left: 0, bottom: 0}} height={this.props.height} width={this.props.width} >
                            <defs>
                                <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                                </linearGradient>
                                <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                                </linearGradient>
                            </defs>
                            <CartesianGrid stroke="#eee" fill="#fff"/>
                            <XAxis tickFormatter={this.dateFormat} tick={{fontSize: 10, fill:'#fff'}} dataKey="name" padding={{left: 20, right: 20 }}  />
                            <YAxis tick={{fontSize: 10, fill:'#fff'}} tickSize={3} tickFormatter={this.dataFormat} domain={[ dataMin => (dataMin * this.props.chart.properties.min), dataMax => (dataMax * this.props.chart.properties.max) ]} />

                            <Line name={this.props.chart.data[0].year1_label} type="monotone" dataKey="year1" stroke="#82ca9d" strokeWidth={2} decimals={1} label={this.props.chart.type === 1 ? (<CustomizedLabel1 />) : (this.props.chart.type === 2 ? (<CustomizedLabel2 />) : this.props.chart.type === 3 ? (<CustomizedLabel3 />) : (<CustomizedLabel4 />)) } />
                            <Line name={this.props.chart.data[0].year1_unweighted_label} type="monotone" dataKey="year1_unweighted" stroke="#ccc" strokeWidth={1} decimals={1} />
                            <Line name={this.props.chart.data[0].year2_label} type="monotone" dataKey="year2" stroke="#abc4ff" strokeWidth={1} decimals={1} />
                            <Line name={this.props.chart.data[0].future_label} type="monotone" dataKey="future" stroke="#FFA500" strokeWidth={1} decimals={1} label={this.props.chart.type === 1 ? (<CustomizedLabel1 />) : (this.props.chart.type === 2 ? (<CustomizedLabel2 />) : this.props.chart.type === 3 ? (<CustomizedLabel3 />) : (<CustomizedLabel4 />)) } />

                            <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </div>
            ): null }

          </div>
        );
      }
    
    }

    class CustomizedLabel1 extends PureComponent {
      render() {
        const { x, y, value } = this.props;
    
        return (
          <text x={x} y={y} dx={0} dy={-8} fill='#4e4e4e' fontSize={10} textAnchor="middle">
            {'$' + parseFloat(value).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </text>
        );
      }
    }
    
    class CustomizedLabel2 extends PureComponent {
      render() {
        const { x, y, value } = this.props;
    
        return (
          <text x={x} y={y} dx={0} dy={-8} fill='#4e4e4e' fontSize={10} textAnchor="middle">
            {'$' + parseFloat(parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
          </text>
        );
      }
    }
    
    class CustomizedLabel3 extends PureComponent {
      render() {
        const { x, y, value } = this.props;
    
        return (
          <text x={x} y={y} dx={0} dy={-8} fill='#4e4e4e' fontSize={10} textAnchor="middle">
            {parseFloat(value).toFixed(1) + '%'}
          </text>
        );
      }
    }

    class CustomizedLabel4 extends PureComponent {
      render() {
        const { x, y, value } = this.props;
    
        return (
          <text x={x} y={y} dx={0} dy={-8} fill='#4e4e4e' fontSize={10} textAnchor="middle">
            {'$' + nFormatter(value)}
          </text>
        );
      }
    }
    
    export default withRouter(WidgetOpsisChartCard);
