import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import s from './Menu.module.scss';

import { Button } from 'reactstrap';

import { scurl } from '../../core/utils.js';

import isScreen from '../../core/screenHelper';

import Cookies from 'js-cookie';

class Menu extends React.Component {
  
  static propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    page: PropTypes.bool,
    autoFocus: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    user: PropTypes.object.isRequired
  };

  static defaultProps = {
    page: false,
    autoFocus: false,
  };

  constructor(props) {
      
    super(props);

    this.state = {
      verticalHeight: 500
    };

    this.selectPage = this.selectPage.bind(this);
    this.doLogout = this.doLogout.bind(this);
  }

  doLogout() {    
    scurl({
      path: '/users/deauth',
      type: 'simple'
    }, function(err, results) {
      if(err) {
        console.log(err);
        console.log('error removing token');
        Cookies.remove("sh_token");
        localStorage.removeItem("sh_state");
        this.props.history.push('/#/login');
      } else {
        console.log(results);
        Cookies.remove("sh_token");
        localStorage.removeItem("sh_state");
        this.props.history.push('/#/login');
      }
    }.bind(this));
    
  }

  selectPage = (e, slug) => {
    e.preventDefault();
    this.props.history.push('/app/' + slug);
    this.props.toggleMenu();
  }

  render() {

    return (
      <section className={`${s.menu} card navbar-menu`}>
        <div>
          <div className="gutter-top-fixed-2 gutter-left-fixed-2 gutter-right-fixed-2 gutter-bottom-fixed-2">
            {this.props.user.pages.map((page, i) =>
              <div key={i} >
                <button className="header-link" onClick={((e) => this.selectPage(e, page.url))}><strong>{page.name}</strong></button>
                {isScreen('md') || isScreen('lg') || isScreen('xl') ? (
                  <p className="small">{page.description}</p>
                ) : null}
                {parseInt(i) < (this.props.user.pages.length - 1) ? (
                  <hr/>
                ): null}
              </div>
            )}
            {this.props.user.admin ? (
              <div>
                <hr/>
                <button className="header-link" onClick={((e) => this.selectPage(e, 'admin'))}><strong>Admin Tools</strong></button>
                {isScreen('md') || isScreen('lg') || isScreen('xl') ? (
                  <p className="small">Site utilities for admins only.</p>
                ): null}
              </div>
            ) : null}
          </div>

        </div>
        <footer className={[s.cardFooter, 'text-sm', 'card-footer'].join(' ')}>
          <Button
            color="link"
            className="btn-xs float-right py-0"
            onClick={() => this.doLogout()}
            id="logout-btn"
          >
            <span>Logout</span>
          </Button>
          <span className="fs-mini"><i className="la la-user" /> {this.props.user.first}&nbsp;{this.props.user.last}</span>
        </footer>
      </section>
    );
  }
}

function mapStateToProps(store) {
  return {
    filter: store.filter
  };
}

export default withRouter(connect(mapStateToProps)(Menu));
