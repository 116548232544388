import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ListGroup,
  ListGroupItem,
  Row, 
  Col
} from 'reactstrap';

import s from './ListGroup.module.scss';

import { filterUpdated, filterState} from '../../../core/utils';

class FilterSummary extends React.Component {
  
  static propTypes = {
    toggleFilter: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      filter_props: {
        set: false,
        limit: {
          engagements: false,
          sources: false,
          owners: false,
          devices: [],
          countries: [],
          refunds: false
        },
        exclude: {
          engagements: false,
          owners: false,
          sources: false,
          countries: [],
          refunds: false
        }
      }
    };

    this.setSummary = this.setSummary.bind(this);
  }

  componentDidMount() {
    this.setSummary();
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'FILTER COMPONENT - SUMMARY')) {
      this.setSummary();
    }
  }

  setSummary() {

    this.setState({
      filter_props: {
        set: false,
        limit: {
          engagements: false,
          owners: false,
          sources: false,
          devices: false,
          countries: false,
          refunds: false
        },
        exclude: {
          engagements: false,
          owners: false,
          sources: false,
          countries: false,
          refunds: false
        }
      }
    }, function() {

      var set = filterState(this.props.filter);
      
      this.setState({
        filter_props: set
      });

    });
          
  }

  render() {
    
    return (
      
      <div>

        {this.state.filter_props.set ? (
          
          <div className="gutter-top-fixed-4 gutter-bottom-fixed-10">

            {this.state.filter_props.limit.engagements ? (
              <div className="gutter-top-fixed-2">
                <h6><strong>LIMIT SHOWS</strong></h6>
                <ListGroup>
                  {this.props.filter.limit.engagements.map((engagement, i) =>
                    <ListGroupItem key={i} className={s.listGroupItem}>
                      <Row>
                        <Col xs={10} sm={10} md={10} lg={10}>
                          <strong>{engagement.show}</strong>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} className="text-center">
    
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            ) : ( 
              this.state.filter_props.exclude.engagements ? (
                <div className="gutter-top-fixed-2">
                  <h6><strong>EXCLUDE SHOWS</strong></h6>
                  <ListGroup>
                    {this.props.filter.exclude.engagements.map((engagement, i) =>
                      <ListGroupItem key={i} className={s.listGroupItem}>
                        <Row>
                          <Col xs={10} sm={10} md={10} lg={10}>
                            <strong>{engagement.show}</strong>
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} className="text-center">
            
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )}
                  </ListGroup>
                </div>
              ) : null
            )}

            {this.state.filter_props.limit.owners ? (
              <div className="gutter-top-fixed-2">
                <h6><strong>LIMIT OWNERS</strong></h6>
                <ListGroup>
                  {this.props.filter.limit.owners.map((owner, i) =>
                    <ListGroupItem key={i} className={s.listGroupItem}>
                      <Row>
                        <Col xs={10} sm={10} md={10} lg={10}>
                          <strong>{owner.label}</strong>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} className="text-center">
    
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            ) : null}

            {this.state.filter_props.limit.experiments ? (
              <div className="gutter-top-fixed-2">
                <h6><strong>LIMIT EXPERIMENTS</strong></h6>
                <ListGroup>
                  {this.props.filter.limit.experiments.map((experiment, i) =>
                    <ListGroupItem key={i} className={s.listGroupItem}>
                      <Row>
                        <Col xs={10} sm={10} md={10} lg={10}>
                          <strong>{experiment.label}</strong>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} className="text-center">
    
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            ) : null}

            {this.state.filter_props.limit.sources ? (
              <div className="gutter-top-fixed-2">
                <h6><strong>LIMIT SOURCES</strong></h6>
                <ListGroup>
                  {this.props.filter.limit.sources.map((source, i) =>
                    <ListGroupItem key={i} className={s.listGroupItem}>
                      <Row>
                        <Col xs={10} sm={10} md={10} lg={10}>
                          <strong>{source.label}</strong>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} className="text-center">
    
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            ) : null}

            {this.state.filter_props.limit.devices ? (
              <div className="gutter-top-fixed-2">
                <h6><strong>LIMIT DEVICES</strong></h6>
                <ul>
                  {this.props.filter.limit.devices.map((device, i) =>
                    <li key={i} className={s.listGroupItem}>
                      <Row>
                        <Col xs={10} sm={10} md={10} lg={10}>
                          <strong>{device.label}</strong>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} className="text-center">
    
                        </Col>
                      </Row>
                    </li>
                  )}
                </ul>
              </div>
            ) : null}

            {this.state.filter_props.limit.refunds ? (
              <div className="gutter-top-fixed-3">
                <h6><strong>LIMIT REFUNDS</strong></h6>
                <p>Showing only refund transactions</p>
              </div>
            ) : null}

            {this.state.filter_props.exclude.refunds ? (
              <div className="gutter-top-fixed-3">
                <h6><strong>EXCLUDE REFUNDS</strong></h6>
                <p>Showing only new transactions</p>
              </div>
            ) : null}


            {this.state.filter_props.limit.underwater ? (
              <div className="gutter-top-fixed-3">
                <h6><strong>LIMIT UNDERWATER</strong></h6>
                <p>Showing only transactions with greater access fees than service fees</p>
              </div>
            ) : null}


          </div>

        ) : (

          <div className="gutter-top-fixed-4 gutter-bottom-fixed-10">
           <h6><strong>NOTHING FILTERED</strong></h6>
           <p>Use tabs above to Limit or Exclude orders</p>
          </div>

        )}

      </div>      

    );
  }
}

function mapStateToProps(store) {
  return {
    filter: store.filter
  };
}

export default connect(mapStateToProps)(FilterSummary);
