import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  ButtonGroup
} from 'reactstrap';

import WidgetAffiliateSales from '../../widgets/affiliates/Sales';
import WidgetAffiliateShows from '../../widgets/affiliates/Shows';
import WidgetAffiliateField from '../../widgets/affiliates/Field';
import WidgetAffiliateExport from '../../widgets/affiliates/Export';

import isScreen from '../../core/screenHelper';

import s from './Affiliate.module.scss';

class Affiliate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    };

    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  setTabs(){

      var tabSelected = 0;
      var tabs = [
        {
          id: 0,
          icon: 'fa-light fa-cash-register fa-lg',
          name: 'Summary'
        },
        {
          id: 1,
          icon: 'fa-light fa-users-gear fa-lg',
          name: 'Details'
        },
        {
          id: 2,
          icon: 'fa-light fa-download fa-lg',
          name: 'Export'
        }
      ];
      
      this.setState({
        tabs: tabs,
        tabSelected: tabSelected
      });

  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  render() {
  
    return (
      <div className={s.root}>

        <div className="menuContainer lg">
          <Nav className="mt-4">
            <NavItem className="nopad-bottom nomargin-bottom">
                <h5 className="text-danger">Affiliate Dashboard</h5>
                {this.state.tabs ? (
                <ButtonGroup>
                  {this.state.tabs.map((tab, i) =>
                    <Button key={i}
                      color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                      active={this.state.tabSelected === i}
                    ><i className={['fa',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                    <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                  )}
                </ButtonGroup>
                ) : null}
            </NavItem>
          </Nav>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === 0 ? (
            <div>
  
              <WidgetAffiliateSales sales_metric="affiliate_commission" />

              <div className="gutter-top-fixed-2 gutter-bottom-fixed-4">
                <WidgetAffiliateShows sales_metric="affiliate_commission" />
              </div>

            </div>
          ): null}

          {this.state.tabSelected === 1 ? (
            <div>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div>
                    <WidgetAffiliateField field="$customer.dma" title="DMA" field_header="DMA" sales_metric="affiliate_commission" refunds={0} />
                    <WidgetAffiliateField field="$customer.country" title="Country" field_header="Country" className="mt-4" sales_metric="affiliate_commission" refunds={0} />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div>
                    <WidgetAffiliateField field="$wallet" title="Wallets" field_header="Wallet" className="mt-4" share_to_total={true} sales_metric="affiliate_commission" refunds={0} />
                    <WidgetAffiliateField field="$quantity" title="Per Order QTY" field_header="QTY" className="mt-4" sales_metric="affiliate_commission" refunds={0} />
                  </div>
                </Col>
              </Row>
            </div>
          ): null}

          {this.state.tabSelected === 2 ? (
            <div>
              <WidgetAffiliateExport className="gutter-top-fixed-2" />  
            </div>
          ): null}
        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Affiliate));
