import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { Button, Table } from 'reactstrap';

class WidgetOpsisTrends extends React.Component {

  static propTypes = {
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false
    }

    this._isMounted = false;
    this.loadSettings = this.loadSettings.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadSettings();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadSettings() {
    
    this.setState({
      isLoaded: false,
      results: null
    }, function() {

      var opts = {
        path: '/opsis/trends',
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  fire = () => { 

    this.setState({
      isLoaded: false
    }, function() {
      
      var url = '/opsis/fire/trends';

      var opts = {
        path: url,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          this.setState({
            isLoaded: true,
            results: results,
          });
        }
      }.bind(this));

    });
  }

  render() {

    var title = <h5><span className="text-danger">Trends</span> <small className="text-muted">For forecast and weekly reports</small></h5>;
    return (

      <div>

        <Widget title={title}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-2">

              <p><strong>Forecast Date: {this.state.results.trends.forecast_date}</strong></p>

              <Table className="gutter-top-fixed-2">
                <thead>
                  <tr>
                    <th width="50%">Days Before Performance</th>
                    <th width="50%">Percent In the Door</th>
                  </tr>
                </thead>
                <tbody> 
                  {this.state.results.trends.leads.map((dayout, i) =>
                    <tr key={i}>
                      <td>
                        <span className={'text-capitalize ' + (this.props.print ? 'fs-mini' : null)}>{dayout.key}</span>
                      </td>
                      <td>
                        <span className={this.props.print ? 'fs-mini' : null}>{parseFloat(dayout.percent*100).toFixed(1)}%</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <hr/>

              <div className="text-center">
                <Button className="btn-success text-white" onClick={() => this.fire()}>
                  <i className="fa-light fa-fire" /> SET TRENDS
                </Button>
              </div>

            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisTrends));
