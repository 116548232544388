import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { getUser } from '../../core/utils.js';

import WidgetSalesTimespans from '../../widgets/transactions/Timespans';
import WidgetShows from '../../widgets/transactions/Shows';
import WidgetGeo from '../../widgets/transactions/Geo';
import WidgetRepeats from '../../widgets/transactions/Repeats';
import WidgetMargin from '../../widgets/transactions/Margin';
import WidgetOpsisPaceWeeks from '../../widgets/opsis/PaceWeeks';

import WidgetSearch from '../../widgets/search/Skeptic';
import WidgetSessions from '../../widgets/content/Sessions';

import {
  Row,
  Col
} from 'reactstrap';

import isScreen from '../../core/screenHelper';

import s from './Dashboard.module.scss';

class Dashboard extends React.Component {

  render() {
  
    return (
      <div className={s.root}>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          <div className={s.sidesWrapper}>
            <div className={s.analyticsSide}>
              <Row>
                <Col xs={12} md={12} lg={6} xl={6}>
                  <div className="pb-xlg h-100">
                    <WidgetSalesTimespans affiliate={false} dashboard={true} />
                  </div>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3}>
                  <div className="pb-xlg h-100">
                  <WidgetOpsisPaceWeeks dashboard={true} />
                  </div>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3} className={!isScreen('xl') ? 'd-none' : null}>
                  <div className="pb-xlg h-100">
                    <WidgetMargin dashboard={true} />
                    
                  </div>
                </Col>
                <Col lg={12} xs={12}>
                  <WidgetShows affiliate={false} dashboard={true} />
                </Col>
                <Col xs={12} lg={6} xl={4} className="gutter-bottom-fixed-2">
                  <WidgetGeo affiliate={false} dashboard={true} />
                </Col>
                <Col xs={12} lg={6} xl={4} className="gutter-bottom-fixed-2">
                  <WidgetSearch affiliate={false} dashboard={true} />
                </Col>
              </Row>
              <div className="gutter-bottom-fixed-1">&nbsp;</div>
            </div>
            <div className={s.analyticsSide}>
              <Row>
                <Col xs={12} md={12} lg={12} xl={12} className={[s.lastSideElement,''].join()}>
                  <div className="pb-xlg">
                    <Row>
                      <Col xs={12} md={6} lg={6} xl={12}>
                        <div className="gutter-bottom-fixed-3">
                          <WidgetRepeats affiliate={false} dashboard={true} />
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={6} xl={12}>
                        <>
                          <WidgetSessions affiliate={false} dashboard={true} />
                          <div className="gutter-bottom-fixed-1">&nbsp;</div>
                        </>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={12} className={[s.lastSideElement,isScreen('xl') ? 'd-none' : ''].join()}>
                  <div className="pb-xlg h-100">
                    {/* <WidgetSessions affiliate={false} dashboard={true} /> */}
                  </div>
                </Col>
              </Row>
            </div>
          </div>


        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Dashboard));
