import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { scurl, getUser } from '../../../core/utils.js';
import isScreen from '../../../core/screenHelper';

import {
  Nav,
  NavItem,
  Button,
  ButtonGroup,
} from 'reactstrap';

import Widget from '../../../components/Widget/Widget';
import WidgetSpacer from '../../../widgets/utils/Spacer';

import WidgetOpsisEngagements from '../../../widgets/opsis/Engagements';
import WidgetOpsisForecastWeeks from '../../../widgets/opsis/ForecastWeeks';

class OpsisVenue extends React.Component {

  constructor(props) {
    super(props);

    var tabs = [
      {
        id: 0,
        icon: 'fa-star',
        name: 'Engagements'
      }
    ];

    this.state = {
      isLoaded: false,
      tabSelected: 0,
      tabs: tabs
    };

    this.setVenue = this.setVenue.bind(this);
    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setVenue(this.props.match.params.id);
    this.setTabs();
  };

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  setVenue = (id) => {

    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/opsis/venues/' + id,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          this.setState({
            isLoaded: true,
            results: results,
          });
        }
      }.bind(this));
    }); 

  }

  setTabs = () => { 
    getUser(function(err, user) {
      if(err) {
        console.log('error retreiving user');
      } else {
        var tabs = this.state.tabs;
        this.setState({
          tabs: tabs
        });
      }
    }.bind(this));
  }

  selectOpsisAdmin = (e) => {
    e.preventDefault();
    this.props.history.push('/app/opsis/admin');
  }

  render() {

    return (
      
      <div>

        {this.state && this.state.isLoaded ? (
          <div>
            
            {this.state.results.venue ? (
              <div>
                <div className="menuContainer lg">

                <Nav className="mt-4">
                  <NavItem className="nopad-bottom nomargin-bottom">
                      <h5 className="text-danger"><span className="pl-2">Opsis : {this.state.results.venue.name}</span></h5>
                      {this.state.tabs ? (
                      <ButtonGroup>
                        <Button color="default" className="mr-xs" onClick={((e) => this.selectOpsisAdmin(e))}><i className="fa fa-circle-arrow-left fa-light fa-lg text-danger" /></Button>
                 
                        {this.state.tabs.map((tab, i) =>
                          <Button key={i}
                            color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                            active={this.state.tabSelected === i}
                          ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                          <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                        )}
                      </ButtonGroup>
                      ) : null}
                  </NavItem>
                </Nav>
 
                </div>

                <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

                  {this.state.results.venue.id ? (
                    <div>

                      {this.state.tabSelected === 0 ? (
                        <div className="gutter-top-fixed-4">
                          <WidgetOpsisForecastWeeks venue_id={this.state.results.venue.id} print={false} summary={true} />
                          <WidgetOpsisEngagements venue_id={this.state.results.venue.id} engagements={this.state.results.venue.engagements} print={false} />
                        </div>
                      ): null}

                      {this.state.tabSelected === 1 ? (
                        <WidgetOpsisForecastWeeks venue_id={this.state.results.venue.id} print={false} />
                      ): null}

                    </div>
                  ) : null}
                  
                 </div>
              </div>
            ) : (
              this.state.error ? (
                <Widget>
                  <h5 className="page-title text-danger">
                    Show failed to load<br/>
                    <br/><small className="text-muted">{this.state.error.message}</small>
                  </h5>
                </Widget>
              ) : null
            )}
          </div>
          
        ) : (
          <p>Loading...</p>
        )}

        <WidgetSpacer print={true} />
      </div>
    );

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(OpsisVenue));
