import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import moment from 'moment';

import { nFormatter } from '../../../../core/utils';

import Displays from '../../../../components/Displays';

import {
    Row,
    Col,
    Button
  } from 'reactstrap';

import isScreen from '../../../../core/screenHelper';

class WidgetOpsisForecastWeekCard extends React.Component {

  static propTypes = {
    week: PropTypes.object,
    print: PropTypes.bool,
    venue: PropTypes.bool
  };

  static defaultProps = {
    print: false,
    venue: false
  };

  constructor(props) {
    super(props);

    this._isMounted = false;
    
    var show_count = 12;
    var show_cols = [4,4,4,4,4];
    if(!this.props.print) {
      if((isScreen('xs') || isScreen('sm'))) {
        show_count = 3
        show_cols = [4,4,4,4,4];
      } else {
        if(isScreen('md')) {
          show_count = 6
          show_cols = [4,4,4,4,4];
        }
      }
    }

    this.state = {
      expand: false,
      show_count: show_count,
      show_cols: show_cols,
      chart: {
        data: []
      }
    };
  }

  selectVenue = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/opsis/weeks/' + id);
  }

  componentDidMount() {

  }

  toggle = () => { 

    const section = document.querySelector('#block_' + this.props.week.weekending);
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );

    this.setState({
      expand: !this.state.expand
    }, function() {
      //this.loadWeek();
    });
  }

  render() {

    return (
      <div className="">
        {this.state ? (

          <div>

              <Row className="mt-3" id={'block_' + this.props.week.weekending}>
                <Col xs={12} sm={5} md={5} lg={5} xl={5} className="gutter-top-fixed-2">
                  <Row className="m-0 p-0">
                    <Col xs={3} sm={3} md={2} lg={1} xl={1}>
                      <h5>
                        <Button className="btn btn-default btn-xs inline-block text-inline" onClick={() => this.toggle()}>
                          <i className={'fa-light ' + (this.state.expand ? 'fa-minus' : 'fa-plus')} />
                        </Button>
                      </h5>
                    </Col>
                    <Col xs={9} sm={9} md={10} lg={11} xl={11}>
                      <h5 className={'p-0 m-0 text-capitalize ' + (this.props.print ? '' : null)}>
                        <p>{this.props.week.weekending}</p>
                      </h5>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
                  {this.props.week.compare ? (
                     this.props.venue ? (
                      <h5>${nFormatter(this.props.week.compare.forecast.net,2)}</h5>
                     ) : (
                      <h5>${nFormatter(this.props.week.compare.net,2)}</h5>
                     )
                  ): null}
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
       
                    {this.props.venue ? (
                      <h5 className="fw-bold">${nFormatter(this.props.week.forecast.net,2)}</h5>
                     ) : (
                      <h5 className="fw-bold">${nFormatter(this.props.week.net,2)}</h5>
                     )}
                    
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
                  {this.props.week.compare ? (
                    parseInt(this.props.week.net) !== parseInt(this.props.week.compare.net) ? (
                        this.props.venue ? (
                          <div>
                            <h5>${nFormatter(this.props.week.forecast.net-this.props.week.compare.forecast.net,2)}</h5>
                            <p><Displays a={parseInt(this.props.week.forecast.net)} b={parseInt(this.props.week.compare.forecast.net)} format="percent" /></p>
                          </div>
                        ) : (
                          <div>
                            <h5>${nFormatter(this.props.week.net-this.props.week.compare.net,2)}</h5>
                            <p><Displays a={parseInt(this.props.week.net)} b={parseInt(this.props.week.compare.net)} format="percent" /></p>
                          </div>
                        )
                      ) : (
                        <h5>--</h5>
                      )
                    ): null}
                </Col>
              </Row>              
              
              {this.state.expand ? (
                <div className="gutter-top-fixed-2">

                    <Row>
                        <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                            <p className="fs-mini text-muted text-center">Qty</p>
                            <h4 className="text-center"><Displays a={parseInt(this.props.week.forecast.qty)} format="comma" /></h4>
                            <p className="fs-mini text-muted text-center">--</p>
                            {this.props.week.compare ? (
                              <div>
                                <h6 className="text-center text-warning"><Displays a={parseInt(this.props.week.compare.forecast.qty)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">--</p>
                              </div>
                            ) : null}
                            {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                              <div>
                                <h6 className="text-center text-danger"><Displays a={parseInt(this.props.week.actuals.transactions.qty)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">--</p>
                              </div>
                            ) : null}
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                            <p className="fs-mini text-muted text-center">Face</p>
                            <h4 className="text-center">$<Displays a={parseInt(this.props.week.forecast.face)} format="comma" /></h4>
                            <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.props.week.forecast.face/this.props.week.forecast.qty).toFixed(2))}</p>
                            {this.props.week.compare ? (
                              <div>
                                <h6 className="text-center text-warning">$<Displays a={parseInt(this.props.week.compare.forecast.face)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.props.week.compare.forecast.face/this.props.week.compare.forecast.qty).toFixed(2))}</p>
                              </div>
                            ) : null}
                            {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                              <div>
                                <h6 className="text-center text-danger">$<Displays a={parseInt(this.props.week.actuals.transactions.face)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.props.week.actuals.transactions.face/this.props.week.actuals.transactions.qty).toFixed(2))}</p>
                              </div>
                            ) : null}
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                            <p className="fs-mini text-muted text-center">Fees</p>
                            <h4 className="text-center">$<Displays a={parseInt(this.props.week.forecast.fees)} format="comma" /></h4>
                            <p className="fs-mini text-muted text-center">{parseFloat((this.props.week.forecast.fees/this.props.week.forecast.face) * 100).toFixed(1)}%</p>
                            {this.props.week.compare ? (
                              <div>
                                <h6 className="text-center text-warning">$<Displays a={parseInt(this.props.week.compare.forecast.fees)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">{parseFloat((this.props.week.compare.forecast.fees/this.props.week.compare.forecast.face) * 100).toFixed(1)}%</p>
                              </div>
                            ) : null}
                            {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                              <div>
                                <h6 className="text-center text-danger">$<Displays a={parseInt(this.props.week.actuals.transactions.fees)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">{parseFloat((this.props.week.actuals.transactions.fees/this.props.week.actuals.transactions.face) * 100).toFixed(1)}%</p>
                              </div>
                            ) : null}
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                            <p className="fs-mini text-muted text-center">Access</p>
                            <h4 className="text-center">$<Displays a={parseInt(this.props.week.forecast.access)} format="comma" /></h4>
                            <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.props.week.forecast.access/this.props.week.forecast.qty).toFixed(2))}</p>
                            {this.props.week.compare ? (
                              <div>
                                <h6 className="text-center text-warning">$<Displays a={parseInt(this.props.week.compare.forecast.access)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.props.week.compare.forecast.access/this.props.week.compare.forecast.qty).toFixed(2))}</p>
                              </div>
                            ) : null}
                            {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                              <div>
                                <h6 className="text-center text-danger">$<Displays a={parseInt(this.props.week.actuals.transactions.access)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.props.week.actuals.transactions.access/this.props.week.actuals.transactions.qty).toFixed(2))}</p>
                              </div>
                            ) : null}
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                            <p className="fs-mini text-muted text-center">Affiliate</p>
                            <h4 className="text-center">$<Displays a={parseInt(this.props.week.forecast.affiliate)} format="comma" /></h4>
                            <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.props.week.forecast.affiliate/this.props.week.forecast.qty).toFixed(2))}</p>
                            {this.props.week.compare ? (
                              <div>
                                <h6 className="text-center text-warning">$<Displays a={parseInt(this.props.week.compare.forecast.affiliate)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.props.week.compare.forecast.affiliate/this.props.week.compare.forecast.qty).toFixed(2))}</p>
                              </div>
                            ) : null}
                            {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                              <div>
                                <h6 className="text-center text-danger">$<Displays a={parseInt(this.props.week.actuals.transactions.affiliate)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">${parseFloat(parseFloat(this.props.week.actuals.transactions.affiliate/this.props.week.actuals.transactions.qty).toFixed(2))}</p>
                              </div>
                            ) : null}
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                            <p className="fs-mini text-muted text-center">CC Fee</p>
                            <h4 className="text-center">$<Displays a={parseInt(this.props.week.forecast.cc_fees)} format="comma" /></h4>
                            <p className="fs-mini text-muted text-center">{parseFloat((this.props.week.forecast.cc_fees/(this.props.week.forecast.fees + this.props.week.forecast.face)) * 100 ).toFixed(2)}%</p>
                            {this.props.week.compare ? (
                              <div>
                                <h6 className="text-center text-warning">$<Displays a={parseInt(this.props.week.compare.forecast.cc_fees)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">{parseFloat((this.props.week.compare.forecast.cc_fees/(this.props.week.compare.forecast.fees + this.props.week.compare.forecast.face)) * 100 ).toFixed(2)}%</p>
                              </div>
                            ) : null}
                            {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                              <div>
                                <h6 className="text-center text-danger">$<Displays a={parseInt(this.props.week.actuals.transactions.cc_fees)} format="comma" /></h6>
                                <p className="fs-mini text-muted text-center">{parseFloat((this.props.week.actuals.transactions.cc_fees/(this.props.week.actuals.transactions.fees + this.props.week.actuals.transactions.face)) * 100 ).toFixed(2)}%</p>
                              </div>
                            ) : null}
                        </Col>
                    </Row>
                    <hr/>
                    {!this.props.venue ? (
                      <Row>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">Ticketing</p>
                              <h4 className="text-center">$<Displays a={parseInt(this.props.week.forecast.net)} format="comma" /></h4>
                              {this.props.week.compare ? (
                                <p className="text-center text-warning">$<Displays a={parseInt(this.props.week.compare.forecast.net)} format="comma" /></p>
                              ) : null}
                              {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                <p className="text-center text-danger">$<Displays a={parseInt(this.props.week.actuals.transactions.net)} format="comma" /></p>
                              ) : null}
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">Ad Sales</p>
                              <h4 className="text-center">$<Displays a={parseInt(this.props.week.static.adsales)} format="comma" /></h4>
                              {this.props.week.compare ? (
                                <p className="text-center text-warning">$<Displays a={parseInt(this.props.week.compare.static.adsales)} format="comma" /></p>
                              ) : null}
                              {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                <p className="text-center text-danger">$<Displays a={parseInt(this.props.week.actuals.static.adsales)} format="comma" /></p>
                              ) : null}
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">Search</p>
                              <h4 className="text-center">$-<Displays a={parseInt(this.props.week.static.kenshoo)} format="comma" /></h4>
                              {this.props.week.compare ? (
                                <p className="text-center text-warning">$-<Displays a={parseInt(this.props.week.compare.static.kenshoo)} format="comma" /></p>
                              ) : null}
                              {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                <p className="text-center text-danger">$<Displays a={parseInt(this.props.week.actuals.static.kenshoo)} format="comma" /></p>
                              ) : null}
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">Payroll</p>
                              <h4 className="text-center">$-<Displays a={parseInt(this.props.week.static.payroll)} format="comma" /></h4>
                              {this.props.week.compare ? (
                                <p className="text-center text-warning">$-<Displays a={parseInt(this.props.week.compare.static.payroll)} format="comma" /></p>
                              ) : null}
                              {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                <p className="text-center text-danger">$<Displays a={parseInt(this.props.week.compare.static.payroll)} format="comma" /></p>
                              ) : null}
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">Fixed</p>
                              <h4 className="text-center">$-<Displays a={parseInt(this.props.week.static.fixed)} format="comma" /></h4>
                              {this.props.week.compare ? (
                                <p className="text-center text-warning">$-<Displays a={parseInt(this.props.week.compare.static.fixed)} format="comma" /></p>
                              ) : null}
                              {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                <p className="text-center text-danger">$<Displays a={parseInt(this.props.week.compare.static.fixed)} format="comma" /></p>
                              ) : null}
                          </Col>
                          <Col xs={12} sm={4} md={4} lg={3} xl={2} className="gutter-top-fixed-2">
                              <p className="fs-mini text-muted text-center">Net</p>
                              <h2 className="text-center">$<Displays a={parseInt(this.props.week.net)} format="comma" /></h2>
                              {this.props.week.compare ? (
                                <h6 className="text-center text-warning">$<Displays a={parseInt(this.props.week.compare.net)} format="comma" /></h6>
                              ) : null }
                              {this.props.week.actuals && this.props.week.actuals.transactions && moment(this.props.week.weekending).isSameOrBefore(moment().startOf('day')) ? (
                                <p className="text-center text-danger">$<Displays a={parseInt(this.props.week.actuals.transactions.net+this.props.week.actuals.static.adsales-this.props.week.actuals.static.kenshoo-this.props.week.compare.static.payroll-this.props.week.compare.static.fixed)} format="comma" /></p>
                              ) : null}
                          </Col>
                      </Row>
                    ) : (
                      <div>
                        <h2 className="pt-4 text-center">$<Displays a={parseInt(this.props.week.forecast.net)} format="comma" /></h2>
                        {this.props.week.compare ? (
                          <div className="text-center">
                            <h6 className="text-center text-warning">$<Displays a={parseInt(this.props.week.compare.forecast.net)} format="comma" /></h6>
                            <h6><Displays a={parseInt(this.props.week.forecast.net)} b={parseInt(this.props.week.compare.forecast.net)} format="percent" /></h6>
                          </div>
                        ) : null }
                      </div>
                    )}

                    <h5><a href="/" onClick={((e) => this.selectVenue(e, this.props.week.weekending))}>Full Detail</a></h5>
              
                </div>
              ) : null }

              <hr/>

          </div>
        ) : null }
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisForecastWeekCard));
