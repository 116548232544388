import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { nFormatter } from '../../../../core/utils';
import { ResponsiveContainer, Line, LineChart, XAxis, YAxis, Tooltip, Legend} from 'recharts';

import { Row, Col, Button, Table } from 'reactstrap';

import isScreen from '../../../../core/screenHelper';

import ProgressBar from '../../../../components/Progress';
import Displays from '../../../../components/Displays';

class ChartMagic extends PureComponent {
  render() {
    const { chart, print, aspect } = this.props;

    if(print) {
      return (
        <div>{chart}</div>
      );
    } else {
      return (
        <ResponsiveContainer width="100%" aspect={aspect}>
          {chart}
        </ResponsiveContainer>
      );
    }
  }
}
class WidgetBwTrendWeekCard extends React.Component {

  static propTypes = {
    week: PropTypes.object,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    trends: PropTypes.bool
  };

  static defaultProps = {
    print: false,
    width: 880,
    height: 150,
    chartAspectWidth: 7,
    chartAspectHeight: 1,
    trends: false
  };

  constructor(props) {
    super(props);

    this._isMounted = false;
    
    var show_count = 32;
    var show_cols = [6,6,6,6,6];
    if(!this.props.print) {
      if((isScreen('xs') || isScreen('sm'))) {
        show_count = 20
        show_cols = [12,12,12,12,12];
      } else {
        if(isScreen('md')) {
          show_count = 6
          show_cols = [4,4,6,4,4];
        }
      }
    }

    // var forecasted_engagements = this.props.week.engagements.forecasted;
    // subSort('deferred.net',forecasted_engagements,1);

    // var unforecasted_engagements = this.props.week.engagements.unforecasted;
    // subSort('deferred.net',unforecasted_engagements,1);

    this.state = {
      show_count: show_count,
      show_cols: show_cols,
      actuals: this.props.week.actuals,
      print: this.props.print,
      expand: false,
      isLoaded: false,
      // engagements: {
      //   forecast: forecasted_engagements,
      //   missing: unforecasted_engagements
      // },
      chart: {
        data: []
      }
    };

    this._isMounted = false;



    this.setChart = this.setChart.bind(this);
    this.chartDataFormat = this.chartDataFormat.bind(this);
    this.chartDateFormat = this.chartDateFormat.bind(this);
    this.setChartAspect = this.setChartAspect.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setChartAspect();
    this.setChart();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setChart() {

    var chartData = [];

    this.props.week.timespans.day.forEach(function(block, i) {
      
      if(moment(block.key, 'YYYY-MM-DD').isBefore(moment().add(-1,'days'))) {
        if(block.deferred.net > 1000) {
          var d = {
            date: block.key,
            forecast: block.forecast.net,
            deferred: block.deferred.net
          };
          
          chartData.push(d);
        }
      }
    });

    var reflines = [];
    var min = 0.9;
    var max = 1.1;

    this.setState({
      chart: {
        data: chartData,
        reflines: reflines,
        properties: {
          max: max,
          min: min
        }
      }
    });
  }

  chartDataFormat(x) {
    return '$' + nFormatter(x);
  };

  chartDateFormat(x) {
    return moment(x).format('M/DD');
  };

  handleResize() {
    if(this._isMounted) {
      this.setChartAspect();
    }
  }

  setChartAspect() {
    if((isScreen('xs') || isScreen('sm'))) {
      this.setState({
        chartAspectWidth: 2,
        chartAspectHeight: 1
      });
    } else {
      this.setState({
        chartAspectWidth: this.props.chartAspectWidth,
        chartAspectHeight: this.props.chartAspectHeight
      });
    }
  }

  toggle = () => { 

    const section = document.querySelector('#block_' + this.props.week.weekending);
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );

    this.setState({
      expand: !this.state.expand
    }, function() {
      //this.loadWeek();
    });
  }

  toggleDetail = (id) => { 

    const section = document.querySelector('#block_' + this.props.week.weekending);
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );

    this.setState({
      expand: !this.state.expand
    }, function() {
      //this.loadWeek();
    });
  }

  render() {

    return (

      <div className="w-100">

        <Row className={this.state.expand ? 'mt-3 bg-dark text-white' : 'mt-3'} id={'block_' + this.props.week.weekending}>
          <Col xs={12} sm={3} md={3} lg={3} xl={3} className="gutter-top-fixed-2">
            <Row className="m-0 p-0">
              <Col xs={3} sm={3} md={2} lg={2} xl={2} className="d-print-none">
                <h6>
                  <Button className="btn btn-default btn-xs inline-block text-inline" onClick={() => this.toggle()}>
                    <i className={'fa-light ' + (this.state.expand ? 'fa-minus' : 'fa-plus')} />
                  </Button>
                </h6>
              </Col>
              <Col xs={9} sm={9} md={10} lg={10} xl={10}>
                <h5 className={'text-capitalize ' + (this.props.print ? '' : null) + (this.state.expand ? ' fw-bold' : null)}>
                  {moment(this.props.week.weekending, 'YYYY-MM-DD').format('MMMM DD, YYYY').toUpperCase()}
                </h5>
                <p className="text-left">
                  <ProgressBar completed={parseInt( ((this.props.week.lead_averages.qty+this.props.week.lead_averages.face)/2) * 100)} bgcolor="#339FFF" height={20} width="50%" />
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
            {this.state.expand ? (
              <h5>Actuals</h5>
            ) : null }
            <h5>${nFormatter(this.props.week.ticketing.deferred.net, 2)}</h5>
          </Col>
          <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
            {this.state.expand ? (
              <h5>Projected</h5>
            ) : null }
            <h5>${nFormatter(this.props.week.ticketing.projected.net, 2)}</h5>
          </Col>
          <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
            {this.state.expand ? (
              <h5>Forecast</h5>
            ) : null }
            <h5>${nFormatter(this.props.week.ticketing.forecast.net, 2)}</h5>
          </Col>
          <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2 text-center">
            {this.state.expand ? (
              <h5>+/-</h5>
            ) : null }
            <h5 className={(parseInt(this.props.week.ticketing.projected.net)>parseInt(this.props.week.ticketing.forecast.net)) ? 'text-center text-success' : 'text-center text-danger'}>${nFormatter(this.props.week.ticketing.projected.net-this.props.week.ticketing.forecast.net,2)}</h5>
          </Col>
        </Row>

        {this.state.expand ? (
          <div className="gutter-bottom-fixed-10 w-100">
            <Row className="m-0 p-0">
              <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                <h5 className="font-weight-bold gutter-top-fixed-3">Ticketing Revenue</h5>
                <div className="gutter-top-fixed-1 bg-light border">
                  <Table className="fs-mini nopad-bottom nomargin-bottom border">
                    <thead>
                      <tr>
                          <th width="25%">Metric</th>
                          <th className="text-center" width="25%">Forecast</th>
                          <th className="text-center"width="25%">Pace</th>
                          <th className="text-center" width="25%">+/-</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr>
                        <td>
                          <h6>Ticket Qty</h6>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.ticketing.forecast.qty} format="comma" /></h6>
                          <span className="fs-mini text-muted">&nbsp;</span>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.ticketing.projected.qty} format="comma" /></h6>
                          <span className="fs-mini text-muted">&nbsp;</span>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.ticketing.projected.qty} b={this.props.week.ticketing.forecast.qty} format="percent" /></h6>
                          <span className="fs-mini text-muted">&nbsp;</span>
                        </td>
                      </tr>
                      <tr>
                          <td>
                            <h6>Face Value</h6>
                          </td>
                          <td className="text-center">
                            <h6>$<Displays a={this.props.week.ticketing.forecast.face} format="comma" /></h6>
                            <span className="fs-mini text-muted">${parseFloat(this.props.week.ticketing.forecast.face/this.props.week.ticketing.forecast.qty).toFixed(2)}</span>
                          </td>
                          <td className="text-center">
                            <h6>$<Displays a={this.props.week.ticketing.projected.face} format="comma" /></h6>
                            <span className="fs-mini text-muted">${parseFloat(this.props.week.ticketing.deferred.face/this.props.week.ticketing.deferred.qty).toFixed(2)}</span>
                          </td>
                          <td className="text-center">
                            <h6><Displays a={this.props.week.ticketing.projected.face} b={this.props.week.ticketing.forecast.face} format="percent" /></h6>
                          </td>
                      </tr>
                      <tr>
                          <td>
                            <h6>Service Fees</h6>
                          </td>
                          <td className="text-center">
                            <h6 className="fs-bold">$<Displays a={this.props.week.ticketing.forecast.fees} format="comma" /></h6>
                            <span className="fs-mini text-muted">{parseFloat((this.props.week.ticketing.forecast.fees/this.props.week.ticketing.forecast.face)*100).toFixed(1)}%</span>
                          </td>
                          <td className="text-center">
                            <h6 className="fs-bold">$<Displays a={this.props.week.ticketing.projected.fees} format="comma" /></h6>
                            <span className="fs-mini text-muted">{parseFloat((this.props.week.ticketing.deferred.fees/this.props.week.ticketing.deferred.face)*100).toFixed(1)}%</span>
                          </td>
                          <td className="text-center">
                            <h6 className="fs-bold"><Displays a={this.props.week.ticketing.projected.fees} b={this.props.week.ticketing.forecast.fees} format="percent" /></h6>
                          </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                <h5 className="font-weight-bold gutter-top-fixed-3">Ticketing Costs</h5>
                <div className="gutter-top-fixed-1 bg-light border">
                  <Table className="fs-mini nopad-bottom nomargin-bottom border">
                    <thead>
                      <tr>
                          <th width="25%">Metric</th>
                          <th className="text-center" width="25%">Forecast</th>
                          <th className="text-center"width="25%">Pace</th>
                          <th className="text-center" width="25%">+/-</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr>
                        <td>
                          <h6>Access Fees</h6>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.ticketing.forecast.access} format="comma" /></h6>
                          <span className="fs-mini text-muted">${parseFloat(this.props.week.ticketing.forecast.access/this.props.week.ticketing.forecast.qty).toFixed(2)}</span>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.ticketing.projected.access} format="comma" /></h6>
                          <span className="fs-mini text-muted">${parseFloat(this.props.week.ticketing.deferred.access/this.props.week.ticketing.deferred.qty).toFixed(2)}</span>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.ticketing.projected.access} b={this.props.week.ticketing.forecast.access} format="percent" /></h6>
                        </td>
                      </tr>
                      <tr>
                          <td>
                            <h6>Affiliate Commissions</h6>
                          </td>
                          <td className="text-center">
                            <h6>$<Displays a={this.props.week.ticketing.forecast.affiliate} format="comma" /></h6>
                            <span className="fs-mini text-muted">${parseFloat(this.props.week.ticketing.forecast.affiliate/this.props.week.ticketing.forecast.qty).toFixed(2)}</span>
                          </td>
                          <td className="text-center">
                            <h6>$<Displays a={this.props.week.ticketing.projected.affiliate} format="comma" /></h6>
                            <span className="fs-mini text-muted">${parseFloat(this.props.week.ticketing.deferred.affiliate/this.props.week.ticketing.deferred.qty).toFixed(2)}</span>
                          </td>
                          <td className="text-center">
                            <h6><Displays a={this.props.week.ticketing.projected.affiliate} b={this.props.week.ticketing.forecast.affiliate} format="percent" /></h6>
                          </td>
                      </tr>
                      <tr>
                          <td>
                            <h6>Credit Cards</h6>
                          </td>
                          <td className="text-center">
                            <h6 className="fs-bold">$<Displays a={this.props.week.ticketing.forecast.cc_fees} format="comma" /></h6>
                            <span className="fs-mini text-muted">{parseFloat((this.props.week.ticketing.forecast.cc_fees/(this.props.week.ticketing.forecast.face + this.props.week.ticketing.forecast.fees))*100).toFixed(1)}%</span>
                          </td>
                          <td className="text-center">
                            <h6 className="fs-bold">$<Displays a={this.props.week.ticketing.projected.cc_fees} format="comma" /></h6>
                            <span className="fs-mini text-muted">{parseFloat((this.props.week.ticketing.deferred.cc_fees/(this.props.week.ticketing.deferred.face + this.props.week.ticketing.deferred.fees))*100).toFixed(1)}%</span>
                          </td>
                          <td className="text-center">
                            <h6><Displays a={this.props.week.ticketing.projected.cc_fees} b={this.props.week.ticketing.forecast.cc_fees} format="percent" /></h6>
                          </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                <h5 className="font-weight-bold gutter-top-fixed-3">Totals</h5>
                <div className="gutter-top-fixed-1 bg-light border">
                  <Table className="fs-mini nopad-bottom nomargin-bottom border">
                    <thead>
                      <tr>
                          <th width="25%">Metric</th>
                          <th className="text-center" width="25%">Forecast</th>
                          <th className="text-center"width="25%">Pace</th>
                          <th className="text-center" width="25%">+/-</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr>
                        <td>
                          <h6>Ticketing</h6>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.ticketing.forecast.net} format="comma" /></h6>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.ticketing.projected.net} format="comma" /></h6>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.ticketing.projected.net} b={this.props.week.ticketing.forecast.net} format="percent" /></h6>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6>Ad Sales</h6>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.static.adsales.forecast} format="comma" /></h6>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.static.adsales.projected} format="comma" /></h6>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.static.adsales.projected} b={this.props.week.static.adsales.forecast} format="percent" /></h6>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6>Paid Search</h6>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.static.kenshoo.forecast} format="comma" /></h6>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.static.kenshoo.projected} format="comma" /></h6>
                        </td>
                        <td className="text-center">
                          <h6><Displays a={this.props.week.static.kenshoo.projected} b={this.props.week.static.kenshoo.forecast} format="percent" /></h6>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="fs-bold">Total</h6>
                        </td>
                        <td className="text-center">
                          <h6 className="fs-bold"><Displays a={(this.props.week.ticketing.forecast.net+this.props.week.static.adsales.forecast)-this.props.week.static.kenshoo.forecast} format="comma" /></h6>
                        </td>
                        <td className="text-center">
                        <h6 className="fs-bold"><Displays a={(this.props.week.ticketing.projected.net+this.props.week.static.adsales.projected)-this.props.week.static.kenshoo.projected} format="comma" /></h6>
                        </td>
                        <td className="text-center">
                          <h6 className="fs-bold"><Displays a={(this.props.week.ticketing.projected.net+this.props.week.static.adsales.projected)-this.props.week.static.kenshoo.projected} b={(this.props.week.ticketing.forecast.net+this.props.week.static.adsales.forecast)-this.props.week.static.kenshoo.forecast} format="percent" /></h6>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
            

            <hr className="pb-0 mb-0" />
            
            <div className="p-0 m-0 w-100">

              {this.state.chart.data.length > 0 ? (
                <div className="gutter-right-fixed-10">
                  <h5 className="font-weight-bold gutter-top-fixed-3">Daily Sales versus Forecast <span className="fs-tiny text-muted fw-normal"> Chart tracks net profit.</span></h5>
                  <ChartMagic
                    chart = {
                      <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                        <defs>
                          <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                          </linearGradient>
                          <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                          </linearGradient>
                        </defs>
                        <XAxis tickFormatter={this.chartDateFormat} tick={{fontSize: 10}} dataKey="date" padding={{left: 0, right: 0}} />
                        <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.chartDataFormat} domain={[ dataMin => (dataMin * this.state.chart.properties.min), dataMax => (dataMax * this.state.chart.properties.max) ]} />

                        <Line name="Actuals" type="monotone" dataKey="deferred" stroke="#82ca9d" strokeWidth={1.5} dot={false} />
                        <Line name="Forecast" type="monotone" dataKey="forecast" stroke="#ccc" strokeWidth={1.5} dot={false} />

                        <Legend verticalAlign="top" height={36} align="right" iconType="square" />

                        <Tooltip />
                      </LineChart>
                    }
                    print={this.state.print}
                    aspect={this.state.chartAspectWidth/this.state.chartAspectHeight}
                  />
                  <hr className="pt-0 mt-0" />
                </div>
              ) : null}

              {/* <h5 className="font-weight-bold gutter-top-fixed-3">Forecast Profit by Show <span className="fs-tiny text-muted fw-normal"> (Service Fees less Access Fees, Affiliate Fees and Credit Card Fees)</span></h5>
              <Row>
                  {this.state.engagements.forecast.map((product, i) =>
                    i < this.state.show_count ? (
                      <Col xs={this.state.show_cols[0]} sm={this.state.show_cols[1]} md={this.state.show_cols[2]} lg={this.state.show_cols[3]} xl={this.state.show_cols[4]} key={i} className="gutter-top-fixed-2 w-100">
                          <ProductCard print={this.props.print} product={product} />
                      </Col>
                    ) : null
                )}
              </Row> */}

              <hr/>

            </div>
            <hr/>
          </div>
        ) : null}

        <hr/>

      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetBwTrendWeekCard));
