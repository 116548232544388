import moment from 'moment';
import { DateTime } from 'luxon/build/node/luxon';
import { loadState } from './localStorage.js';
import Cookies from 'js-cookie';

export function surl(opts) {
  
  var token = Cookies.get('sh_token');
  
  if(token) {

    const state = loadState(true);

    opts.type = opts.type || 'universal';
    
    var url = '';
    if(window.location.href.includes('localhost')) {
      url = 'http://127.0.0.1:5050' + opts.path;
    } else {
      url = 'https://api.shadow.haus' + opts.path;
    }

    if (opts.path.indexOf('?') > -1) {
      url += '&';
    } else {
      url += '?';
    }

    url += 'token=' + Cookies.get('sh_token');

    if(state) {
      
      switch (opts.type) {

        case 'simple':
          break;

        case 'thin':
          if(state.filter.dates) {
            url += '&start=' + state.filter.dates.start;
            url += '&end=' + state.filter.dates.end;
          }

          break;

        case 'universal': 

          if(opts.univ_start && opts.univ_end) {

            if(moment(opts.univ_end).isBefore(moment(opts.univ_start))) {
              opts.univ_end = opts.univ_start
            }

            url += '&univ_start=' + opts.univ_start;
            url += '&univ_end=' + opts.univ_end;

            if(opts.perf_start && opts.perf_end) {

              if(moment(opts.perf_end).isBefore(moment(opts.perf_start))) {
                opts.perf_end = opts.perf_start;
              }

              url += '&perf_start=' + opts.perf_start;
              url += '&perf_end=' + opts.perf_end;

            }
            
            if(opts.perf_date) {
              url += '&perf_date=1';
            }
    
          } else {

            if(opts.trans_start && opts.trans_end && opts.perf_start && opts.perf_end) {
              url += '&trans_start=' + opts.trans_start;
              url += '&trans_end=' + opts.trans_end;
              url += '&perf_start=' + opts.perf_start;
              url += '&perf_end=' + opts.perf_end;
            } else {
              if(opts.perf_start && opts.perf_end) {

                if(moment(opts.perf_end).isBefore(moment(opts.perf_start))) {
                  opts.perf_end = opts.perf_start
                }

                url += '&perf_start=' + opts.perf_start;
                url += '&perf_end=' + opts.perf_end;

              } else {

                if(state.filter.dates) {

                  if(moment(state.filter.dates.end).isBefore(moment(state.filter.dates.start))) {
                    state.filter.dates.end = state.filter.dates.start;
                  }

                  if(opts.datetype === 'performance') {
                    url += '&perf_start=' + state.filter.dates.start;
                    url += '&perf_end=' + state.filter.dates.end;
                  } else {
                    if(state.filter.dates.start.length === 0 || state.filter.dates.end.length === 0) {
                      url += '&univ_start=' + moment().add(-7,'day').format('YYYY-MM-DD');
                      url += '&univ_end=' +  moment().add(-1,'day').format('YYYY-MM-DD');
                    } else {
                      url += '&univ_start=' + state.filter.dates.start;
                      url += '&univ_end=' + state.filter.dates.end;
                    }
                  }

                  if(moment(state.filter.dates.start,'YYYY-MM-DD').isAfter(moment())) {
                    opts.perf_date = 1;
                    url += '&perf_date=1';
                  }

                  if(opts.perf_date) {
                    url += '&perf_date=1';
                  }

                  if(opts.share_to_total) {
                    url += '&share_to_total=1';
                  }

                }
              
              }
            }
          }

          if(opts.years) {
            url += '&years=' + opts.years;
          }

          if(opts.field) {
            url += '&_id=' + opts.field;
          }

          if(opts.timespans) {
            url += '&timespans=1';
          }

          if(opts.exclude_zero) {
            url += '&exclude_zero=1';
          }

          if(opts.statusless) {
            url += '&statusless=1';
          }

          if(opts.code) {
            url += '&code=' + opts.code;
          }

          if(opts.experiment) {
            url += '&experiment=1';
          }

          if(opts.max_records) {
            url += '&max_records=' + opts.max_records;
          }

          if(opts.limit_apis) {
            url += '&limit_apis=';
            for (var [limitedApiIndex, limitedApi] of opts.limit_apis.entries()) {
              url += limitedApi;
              if(limitedApiIndex < (opts.limit_apis.length - 1)) {
                url += ',';
              } 
            }
          }

          //filters
          if(opts.product_id) {
            url += '&limit_products=' + opts.product_id;
          } else {
            if(state && state.filter && state.filter.limit && state.filter.limit.engagements && state.filter.limit.engagements.length > 0) {
              url += '&limit_products=';
              for (var [limitedProductIndex, limitedProduct] of state.filter.limit.engagements.entries()) {
                url += limitedProduct.id;
                if(limitedProductIndex < (state.filter.limit.engagements.length - 1)) {
                  url += ',';
                } 
              }
            }
            if(state && state.filter && state.filter.exclude && state.filter.exclude.engagements && state.filter.exclude.engagements.length > 0) {
              url += '&exclude_products=';
              for (var [excludeProductIndex, excludeProduct] of state.filter.exclude.engagements.entries()) {
                url += excludeProduct.id;
                if(excludeProductIndex < (state.filter.exclude.engagements.length - 1)) {
                  url += ',';
                } 
              }
            }
          }

          //owners
          if(state && state.filter && state.filter.limit && state.filter.limit.owners && state.filter.limit.owners.length > 0) {
            url += '&limit_owners=';
            for (var [limitedOwnerIndex, limitedOwner] of state.filter.limit.owners.entries()) {
              url += limitedOwner.id;
              if(limitedOwnerIndex < (state.filter.limit.owners.length - 1)) {
                url += ',';
              } 
            }
          }
          if(state && state.filter && state.filter.exclude && state.filter.exclude.owners && state.filter.exclude.owners.length > 0) {
            url += '&exclude_owners=';
            for (var [excludeOwner,excludeOwnerIndex] of state.filter.exclude.owners.entries()) {
              url += excludeOwner.slug;
              if(excludeOwnerIndex < (state.filter.exclude.owners.length - 1)) {
                url += ',';
              } 
            }
          }

          //experiments
          if(state && state.filter && state.filter.limit && state.filter.limit.experiments && state.filter.limit.experiments.length > 0) {
            url += '&limit_experiments=';
            for (var [limitedExperimentsIndex, limitedExperiments] of state.filter.limit.experiments.entries()) {
              url += limitedExperiments.id;
              if(limitedExperimentsIndex < (state.filter.limit.experiments.length - 1)) {
                url += ',';
              } 
            }
          }

          //sources
          if(state && state.filter && state.filter.limit && state.filter.limit.sources && state.filter.limit.sources.length > 0) {
            url += '&limit_sources=';
            for (var [limitedSourceIndex, limitedSource] of state.filter.limit.sources.entries()) {
              url += limitedSource.id;
              if(limitedSourceIndex < (state.filter.limit.sources.length - 1)) {
                url += ',';
              } 
            }
          }
          if(state && state.filter && state.filter.exclude && state.filter.exclude.sources && state.filter.exclude.sources.length > 0) {
            url += '&exclude_sources=';
            for (var [excludeSource,excludeSourceIndex] of state.filter.exclude.sources.entries()) {
              url += excludeSource.slug;
              if(excludeSourceIndex < (state.filter.exclude.sources.length - 1)) {
                url += ',';
              } 
            }
          }

          //refunds
          if(typeof opts.refunds !== 'undefined') {
            url += '&refunds=' + opts.refunds;
          } else {
            if(state && state.filter && state.filter.exclude && state.filter.exclude.refunds) {
              url += '&refunds=0';
            } else {
              if(state && state.filter && state.filter.limit && state.filter.limit.refunds) {
                url += '&refunds=1';
              }
            }
          }

          //devices
          if(state && state.filter && state.filter.limit && state.filter.limit.devices && state.filter.limit.devices.length > 0) {
            url += '&limit_devices=';
            for (var [limitedDeviceIndex, limitedDevice] of state.filter.limit.devices.entries()) {
              url += limitedDevice.id;
              if(limitedDeviceIndex < (state.filter.limit.devices.length - 1)) {
                url += ',';
              } 
            }
          }

          //underwater
          if(state && state.filter && state.filter.limit && state.filter.limit.underwater) {
            url += '&underwater=1';
          }

          if(opts.fixed_years) {
            url += '&fixed_years=' + opts.fixed_years;
          }

          if(opts.trend_report) {
            url += '&trend_report=1';
          }

          if(opts.sort) {
            url += '&sort=' + opts.sort;
          }

          if(opts.asof) {
            url += '&asof=' + opts.asof;
          }

          if(opts.lookback) {
            url += '&lookback=' + opts.lookback;
          }

          if(opts.deferred_shows) {
            url += '&deferred_shows=1';
          }

          if(opts.performances_through) {
            url += '&performances_through=' + opts.performances_through;
          }

          if(opts.transline_field) {
            url += '&transline_field=' + opts.transline_field;
          }

          if(opts.ga_field) {
            url += '&ga_field=' + opts.ga_field;
          }

          if(opts.campaign_type) {
            url += '&campaign_type=' + opts.campaign_type;
          }

          if(opts.skai_property) {
            url += '&skai_property=' + opts.skai_property;
          }

          if(opts.skai_filter) {
            url += '&skai_filter=' + opts.skai_filter;
          }

          if(opts.ga_property) {
            url += '&ga_property=' + opts.ga_property;
          }

          if(opts.ga_filter) {
            url += '&ga_filter=' + opts.ga_filter;
          }

          if(opts.section) {
            url += '&section=' + opts.section;
          }

          if(opts.lead_groups) {
            url += '&lead_groups=' + opts.lead_groups;
          }

          if(opts.price_groups) {
            url += '&price_groups=' + opts.price_groups;
          }

          if(typeof opts.rollup !== 'undefined') {
            url += '&rollup=' + opts.rollup;
          }

          if(typeof opts.pop !== 'undefined') {
            url += '&pop=' + opts.pop;
          }

          if(typeof opts.transactions !== 'undefined') {
            url += '&transactions=' + opts.transactions;
          }

          if(typeof opts.markup_is_under !== 'undefined') {
            url += '&markup_is_under=' + opts.markup_is_under;
          }

          break;

        default: 
          url += '&token=134679';
          break;
          
      }

    } else {
      console.log('No state for the URL');
    }
    return url;

  } else {
    return '';
  }
};

export function scurl(opts, callback) {
  var url = surl(opts);
  
  if(window.location.href.includes('localhost')) {
    console.log(url);
  }
  
  fetch(url)
    .then(res => res.json())
    .then(
      (data) => {
        if(data.error) {
          callback({
            message: 'Error occured.'
          });
        } else {
          callback(null, data.results);
        }
      }, (error) => {
        callback(error);
  });
};

export function stocurl(opts, callback) {
  var token = Cookies.get('sh_token');
  
  if(token) {

    var url = '';
    if(window.location.href.includes('localhost')) {
      url = 'http://localhost:5555' + opts.path;
    } else {
      url = 'https://stoker.shadow.haus' + opts.path;
    }

    if (opts.path.indexOf('?') > -1) {
      url += '&';
    } else {
      url += '?';
    }

    url += 'token=' + Cookies.get('sh_token');
    
    if(window.location.href.includes('localhost')) {
      console.log(url);
    }
  
    fetch(url)
      .then(res => res.json())
      .then(
        (data) => {
          if(data.error) {
            callback({
              message: 'Error occured.'
            });
          } else {
            callback(null, data.results);
          }
        }, (error) => {
          callback(error);
    });
  } else {
    return '';
  }

};

export function getUser(callback) {
  scurl({
    path: '/users/token',
    type: 'simple'
  }, function(err, results) {
    if(err) {
      callback(err);
    } else {
      callback(null, results.user);
    }
  });
};

export function nFormatter(num, m) {
  
  m = m || 1;
  
  if(num) {
    if(num < 0) {

      num = Math.abs(num);

      if (num >= 1000000000) {
        return '-' + (num / 1000000000).toFixed(1) + 'G';
      }
      if (num >= 1000000) {
        return '-' + (num / 1000000).toFixed(m) + 'M';
      }
      if (num >= 1000) {
        return '-' + (num / 1000).toFixed(0) + 'K';
      }
      if (num < 1000) {
        return '-' + parseInt(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return '-' + num;
    } else {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + 'G';
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(m) + 'M';
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(0) + 'K';
      }
      if (num < 1000) {
        return parseInt(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return num;
    }
  } else {
    return '0';
  }
};

export function comma(num) {
  return parseInt(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function percentDisplay(num, m) {
  
  m = m || 1;
  
  if(num) {
    if(num < 0) {
      if (num <= -1000000000) {
        return (num / 1000000000).toFixed(1) + 'G';
      }
      if (num <= -1000000) {
        return (num / 1000000).toFixed(m) + 'M';
      }
      if (num <= -1000) {
        return (num / 1000).toFixed(0) + 'K';
      }
      if (num < -1000) {
        return parseInt(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return num;
    } else {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + 'G';
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(m) + 'M';
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(0) + 'K';
      }
      if (num < 1000) {
        return parseInt(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return num;
    }
  } else {
    return '0';
  }
};

export function objectEquals(x, y) {
  if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) { return false; }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) { return x === y; }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) { return x === y; }
  if (x === y || x.valueOf() === y.valueOf()) { return true; }
  if (Array.isArray(x) && x.length !== y.length) { return false; }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) { return false; }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) { return false; }
  if (!(y instanceof Object)) { return false; }

  // recursive object equality check
  var p = Object.keys(x);
  return Object.keys(y).every(function (i) { return p.indexOf(i) !== -1; }) &&
      p.every(function (i) { return objectEquals(x[i], y[i]); });
}

export function defaultTimespan(filter) {
  var duration = moment.duration(moment().diff(moment().format('YYYY-MM-DD')));
  
  var data = 'day';

  if(filter.dates.start && filter.dates.end) {
    duration = moment.duration(moment(filter.dates.end).diff(filter.dates.start));
  }

  if((duration.asDays() < 21)) {
    data = 'day';
  } else {
    if(duration.asDays() < 200) {
      data = 'week';
    } else {
      data = 'week';
    }
  }

  return data;
};

export function defaultTimespanV2(filter) {
  var duration = moment.duration(moment().diff(moment().format('YYYY-MM-DD')));
  
  var data = 'days';

  if(filter.dates.start && filter.dates.end) {
    duration = moment.duration(moment(filter.dates.end).diff(filter.dates.start));
  }

  if((duration.asDays() < 21)) {
    data = 'days';
  } else {
    if(duration.asDays() < 200) {
      data = 'weeks';
    } else {
      data = 'weeks';
    }
  }

  return data;
};

export function dateRangeDisplay(dates, format) {
  
  let str = '';

  if(dates.start && dates.end) {
    str = DateTime.fromISO(dates.start).toFormat(format) + ' to ' + DateTime.fromISO(dates.end).toFormat(format)
  }

  return str;
};

export function datespan(filter) {
  var duration = moment.duration(moment().diff(moment().format('YYYY-MM-DD')));
  
  if(filter.dates.start && filter.dates.end) {
    duration = moment.duration(moment(filter.dates.end).diff(filter.dates.start));
  }

  //klog(duration.asDays() + 1);

  return duration.asDays() + 1;
};

export function roundToNearest (number, multiple, roundingFunction) {
  roundingFunction = roundingFunction || Math.round;
  return roundingFunction(number / multiple) * multiple;
}

export function dateShortcut(dates) {
    
  if(dates.id !== 99) {
    dates.end = moment().add(-1,'day').format('YYYY-MM-DD');
  }

  switch(parseInt(dates.id)) {
    case 0:
      dates.start = moment().format('YYYY-MM-DD');
      dates.end = moment().format('YYYY-MM-DD');
      dates.display = 'Today';
      break;
    case 1:
      dates.start = moment().add(-1,'day').format('YYYY-MM-DD');
      dates.display = 'Yesterday';
      break;
    case 2:
      dates.end = moment().add(-1,'day').format('YYYY-MM-DD');
      dates.start = moment().startOf('isoWeek').format('YYYY-MM-DD');
      if(moment().add(-1,'day').isoWeekday() === 7) {
        dates.start = moment(dates.start).add(-7,'days').format('YYYY-MM-DD');
      }
      dates.display = 'Week to Date';
      break;
    case 3:
      dates.start = moment().startOf('month').format('YYYY-MM-DD');
      dates.display = 'Month to Date';
      break;
    case 4:
      dates.start = moment().startOf('year').format('YYYY-MM-DD');
      dates.display = 'Year to Date';
      break;
    case 5:
      dates.end = moment().add(-1,'week').endOf('isoWeek').format('YYYY-MM-DD');
      dates.start = moment().add(-1,'week').startOf('isoWeek').format('YYYY-MM-DD');
      dates.display = 'Last Week';
      break;
    case 7:
        dates.end = moment().add(-1,'week').endOf('isoWeek').format('YYYY-MM-DD');
        dates.start = '2024-05-20';
        dates.display = 'Season to Date';
        break;
    case 8:
        dates.start = '2021-05-01';
        dates.display = 'Since Reboot';
        break;
    case 9:
      dates.start = '2024-07-01';
      dates.display = 'Fiscal to Date';
      break;
    case 99:
      dates.display = moment(dates.start).format('MM/DD') + ' - ' + moment(dates.end).format('MM/DD');
      break;
    default:
      klog('trans date: not known');
      break;
  }
  
  return dates;
};

export function priorYear(date) {
  var cy = moment(date, 'YYYY-MM-DD');
  
  var cy_day =  cy.isoWeekday();
  cy.add(-1,'years');

  while (cy_day !== cy.isoWeekday()) {
    cy.add(1,'days');
  }
  
  return cy.format('YYYY-MM-DD');
};

export function subSort(prop, arr, sort) {
 
    sort = sort || 'asc';
    
    prop = prop.split('.');
    var len = prop.length;

    if(sort === 'asc') {
      arr.sort(function (a, b) {
            var i = 0;
            while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            } else {
                return 0;
            }
        });
    } else {
      arr.sort(function (a, b) {
        var i = 0;
        while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
          if (a > b) {
              return -1;
          } else if (a < b) {
              return 1;
          } else {
              return 0;
          }
      });
    }
    
    return arr;

};

export function klog(str, force) {

  force = force || false;
  
  if(window.location.href.includes('localhost') || force) {
    console.log(str);
  }

};

export function isProd() {
  if( (window.location.href.indexOf('127.0.0.1') > 0) || (window.location.href.indexOf('local.kapi') > 0) ) {
    return false;
  } else {
    return true;
  }
};

export function changeSalesMetric(metric) {
  switch(metric) {
    case 'qty':
      return 'face';
    case 'face':
      return 'fees';
    case 'fees':
      return 'access_fees';
    case 'access_fees':
      return 'affiliate_commission';
    case 'affiliate_commission':
      return 'net_net';
    default:
      return 'qty';
  }
};

export function changeAffiliateSalesMetric(metric) {
  switch(metric) {
    case 'orders':
      return 'qty';
    case 'qty':
      return 'face';
    case 'face':
      return 'fees';
    case 'fees':
      return 'affiliate_commission';
    default:
      return 'orders';
  }
};

export function displaySalesMetric(metric) { 
  switch(metric) {
    case 'orders':
      return 'Orders';
    case 'qty':
      return 'Quantity';
    case 'face':
      return 'Face Value';
    case 'fees':
      return 'Service Fees';
    case 'net_net':
      return 'Net Profit';
    case 'access_fees':
      return 'Access Fees';
    case 'affiliate_commission':
      return 'Affiliate Commissions';
    default:
      return 'Sales'
  }
};

export function displayPreSalesMetric(metric) { 
  switch(metric) {
    case 'orders':
      return '';
    case 'qty':
      return '';
    case 'face':
      return '$';
    case 'fees':
      return '$';
    case 'net_net':
      return '$';
    case 'access_fees':
      return '$';
    case 'affiliate_commission':
      return '$';
    default:
      return ''
  }
};

export function displayMetricFormat(metric, val) { 

  let display = val;
  switch(metric) {
    case 'qty':
      display = formatNumber('X,',val,true);
      break;
    case 'avg_price':
      display = formatNumber('$X.XX',val,false);
      break;
    case 'face':
      display = formatNumber('$X',val,true);
      break;
    case 'fees':
      display = formatNumber('$X',val,true);
      break;
    case 'margin':
      display = formatNumber('X.X%',val,false);
      break;
    case 'net':
      display = formatNumber('$X',val,true);
      break;
    case 'access':
      display = formatNumber('$X',val,true);
      break;
    case 'affiliate':
      display = formatNumber('$X',val,true);
      break;
    default:
      console.log('display mapping failed')
      break;
  }
  return display;
};

export function changeBudgetMetric(metric) {
  switch(metric) {
    case 'qty':
      return 'avg_price';
    case 'avg_price':
      return 'face';
    case 'face':
      return 'fees';
    case 'fees':
      return 'margin';
    case 'margin':
      return 'access';
    case 'access':
      return 'affiliate';
    case 'affiliate':
      return 'net';
    default:
      return 'qty';
  }
};

export function displayBudgetMetric(metric) { 
  switch(metric) {
    case 'qty':
      return 'Quantity';
    case 'avg_price':
      return 'Avg Price';
    case 'face':
      return 'Face Value';
    case 'fees':
      return 'Service Fees';
    case 'margin':
      return 'Margin';
    case 'access':
      return 'Access Fees';
    case 'affiliate':
      return 'Affiliates';
    case 'net':
        return 'Net Profit';
    default:
      return 'Sales'
  }
};

export function budgetMetricFormat(metric, val) { 

  let display = val;
  switch(metric) {
    case 'qty':
      display = formatNumber('X,',val,true);
      break;
    case 'avg_price':
      display = formatNumber('$X.XX',val,false);
      break;
    case 'face':
      display = formatNumber('$X',val,true);
      break;
    case 'fees':
      display = formatNumber('$X',val,true);
      break;
    case 'margin':
      display = formatNumber('X.X%',val,false);
      break;
    case 'net':
      display = formatNumber('$X',val,true);
      break;
    case 'access':
      display = formatNumber('$X',val,true);
      break;
    case 'affiliate':
      display = formatNumber('$X',val,true);
      break;
    default:
      console.log('display mapping failed')
      break;
  }
  return display;
};

export function isWeeks(filter) {
  if( (moment(filter.dates.start, 'YYYY-MM-DD').weekday() === 1) && (moment(filter.dates.end, 'YYYY-MM-DD').weekday() === 0) ) {
    return true;
  } else {
    return false;
  }
}

export function dateIsCovid(dates) {

  if( dates && dates.year && ((parseInt(dates.year) === 2020) || (parseInt(dates.year) === 2021)) ) {
    let start = DateTime.fromISO(dates.start);
    let end = DateTime.fromISO(dates.end);
    if( (end < DateTime.fromISO('2020-03-08')) || (start > DateTime.fromISO('2021-09-01')) ) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function filterUpdated(before, after, origin) {
  
  origin = origin || 'UNKNOWN';

  if(after) {
    if(!objectEquals(before,after)) {
      //klog('FILTER CHANGED - ' + origin);
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
  
};

export function filterState(filter) {
  
  var filter_set = false;
  var limit_engagements = false;
  var limit_owners = false;
  var limit_sources = false;
  var limit_devices = false;
  var limit_countries = false;
  var limit_experiments = false;
  var limit_refunds = false;
  var limit_underwater = false;
  var exclude_engagements = false;
  var exclude_owners = false;
  var exclude_sources = false;
  var exclude_refunds = false;
  
  if(filter && (filter.limit || filter.exclude)) {
    
    if(filter.limit.engagements.length > 0) {
      filter_set = true;
      limit_engagements = true;
    }

    if(filter.limit.owners && filter.limit.owners.length > 0) {
      filter_set = true;
      limit_owners = true;
    }

    if(filter.limit.sources.length > 0) {
      filter_set = true;
      limit_sources = true;
    }

    if(filter.limit.devices && filter.limit.devices.length > 0) {
      filter_set = true;
      limit_devices = true;
    }

    if(filter.limit.countries && filter.limit.countries.length > 0) {
      filter_set = true;
      limit_countries = true;
    }

    if(filter.limit.experiments && filter.limit.experiments.length > 0) {
      filter_set = true;
      limit_experiments = true;
    }

    if(filter.limit.refunds) {
      filter_set = true;
      limit_refunds = true;
    }

    if(filter.limit.underwater) {
      filter_set = true;
      limit_underwater = true;
    }

    if(filter.exclude.engagements.length > 0) {
      filter_set = true;
      exclude_engagements = true;
    }

    if(filter.exclude.owners && filter.exclude.owners.length > 0) {
      filter_set = true;
      exclude_owners = true;
    }

    if(filter.exclude.sources && filter.exclude.sources.length > 0) {
      filter_set = true;
      exclude_sources = true;
    }

    if(filter.exclude.refunds) {
      filter_set = true;
      exclude_refunds = true;
    }

  }

  var set = {
    set: filter_set,
    limit: {
      engagements: limit_engagements,
      owners: limit_owners,
      sources: limit_sources,
      devices: limit_devices,
      countries: limit_countries,
      experiments: limit_experiments,
      refunds: limit_refunds,
      underwater: limit_underwater
    },
    exclude: {
      engagements: exclude_engagements,
      owners: exclude_owners,
      sources: exclude_sources,
      refunds: exclude_refunds
    }
  };

  return set;

};

export function filterCount(filter) {
  
  var count = 0;
  
  if(filter && (filter.limit || filter.exclude)) {
   count += filter.limit.engagements.length;
   if(filter.limit.owners) {
    count += filter.limit.owners.length;
   }
   count += filter.limit.sources.length;
   count += filter.limit.devices.length;
   count += filter.limit.countries.length;
   if(filter.limit.experiments) {
    count += filter.limit.experiments.length;
   }

   count += filter.exclude.engagements.length;
   if(filter.exclude.owners) {
    count += filter.exclude.owners.length;
   }
   count += filter.exclude.sources.length;
   if(filter.limit.refunds) {
    count += 1;
   } else {
    if(filter.exclude.refunds) {
      count += 1;
     }
   }
   if(filter.limit.underwater) {
    count += 1;
   }
  }

  return count;

};

export function compare(x, y) {
  return objectEquals(x,y);
}

export function checkExists(prop) {
	if(typeof(prop) !== 'undefined') {
		return true;
	} else {
		return false;
	}
};

export function formatNumber(format, value, thin) {

  thin = thin | false;

  switch (format) {
    case 'X,':
      return parseFloat(value).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    case '$XM':
      if(thin) {
        return '$' + nFormatter(value);
      } else {
        return '$' + nFormatter(value);
      }
    case 'XM':
      if(thin) {
        return nFormatter(value);
      } else {
        return nFormatter(value);
      }
    case 'X.X':
      if(thin) {
        return parseFloat(value).toFixed(0);
      } else {
        return parseFloat(value).toFixed(1);
      }
    case 'X.XX':
      if(thin) {
        return parseFloat(value).toFixed(0);
      } else {
        return parseFloat(value).toFixed(2);
      }
    case 'X.X%':
      if(thin) {
        return parseFloat(value*100).toFixed(0) + '%';
      } else {
        return parseFloat(value*100).toFixed(1) + '%';
      }
    case 'X.XX%':
      if(thin) {
        return parseFloat(value*100).toFixed(0) + '%';
      } else {
        return parseFloat(value*100).toFixed(2) + '%';
      }
    case '$X':
      return '$' + parseFloat(value).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    case '$X.XX':
      if(thin) {
        return '$' + parseFloat(value).toFixed(0);
      } else {
        return '$' + parseFloat(value).toFixed(2);
      }
    default:
      return value
  }
};


