import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { Row, Col } from 'reactstrap';

import { ResponsiveContainer, Line, LineChart, Label, XAxis, YAxis, ReferenceLine, Tooltip, Legend} from 'recharts';

import Widget from '../../../components/Widget';
import Displays from '../../../components/Displays';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated, nFormatter, changeSalesMetric, changeAffiliateSalesMetric, displaySalesMetric, defaultTimespan, displayPreSalesMetric } from '../../../core/utils';
import isScreen from '../../../core/screenHelper';

class WidgetBwSales extends React.Component {
  
  static propTypes = {
    engagement: PropTypes.object,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    chart: PropTypes.bool,
    fixed_years: PropTypes.number,
    fees: PropTypes.bool,
    timespan: PropTypes.string,
    title: PropTypes.bool,
    sales_metric: PropTypes.string,
    perf_date: PropTypes.bool,
    className: PropTypes.string,
    product_id: PropTypes.number,
    affiliate: PropTypes.bool
  };

  static defaultProps = {
    engagement: null,
    chartAspectWidth: 6,
    chartAspectHeight: 1,
    print: false,
    chart: true,
    width: 1050,
    height: 400,
    fixed_years: 4,
    timespan: 'day',
    fees: true,
    title: true,
    sales_metric: 'face',
    perf_date: false,
    className: '',
    affiliate: true
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      perf_date: this.props.perf_date,
      sales_metric: this.props.sales_metric,
      fixed_years: this.props.fixed_years,
      chartAspectWidth: this.props.chartAspectWidth,
      chartAspectHeight: this.props.chartAspectHeight
    }

    this._isMounted = false;
    this.loadSales = this.loadSales.bind(this);
    this.setChartData = this.setChartData.bind(this);
    this.salesSummaryDataFormat = this.salesSummaryDataFormat.bind(this);
    this.salesSummaryDateFormat = this.salesSummaryDateFormat.bind(this);
    this.areaLabelFormat = this.areaLabelFormat.bind(this);
    this.setChartAspect = this.setChartAspect.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadSales();
    this.setChartAspect();

    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SALES WIDGET')) {
      if(this._isMounted) {
        this.loadSales();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadSales() {
    
    this.setState({
      isLoaded: false,
      results: [],
      chart_timespan: defaultTimespan(this.props.filter)
    }, function() {

      var opts = {
        path: '/transactions/totals',
        type: 'universal',
        timespans: 1,
        years: 5
      };

      if(this.state.perf_date) {
        opts.perf_date = true;
      }

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
            isLoaded: true,
            results: results,
          }, function() {
            this.setChartData();
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  setChartAspect() {
    if((isScreen('xs') || isScreen('sm'))) {
      this.setState({
        chartAspectWidth: 2,
        chartAspectHeight: 1
      });
    } else {
      this.setState({
        chartAspectWidth: this.props.chartAspectWidth,
        chartAspectHeight: this.props.chartAspectHeight
      });
    }
  }

  handleResize() {
    if(this._isMounted) {
      this.setChartAspect();
    }
  }

  setChartData() {
    var chartData = [];

    var labels = ['Current Year','1 Year Ago','2 Years Ago','3 Years Ago','4 Years Ago','5 Years Ago'];
    if((isScreen('xs') || isScreen('sm'))) {
      labels = ['CY','1yr','2yr','3yr','4yr','5yr'];
    }

    if(this.state.results && this.state.results.timespans && this.state.results.timespans.dates && (this.state.results.timespans.dates.length > 0) && (this.state.results.timespans.dates[0].data[this.state.chart_timespan].length > 0)) {

      this.state.results.timespans.dates[0].data[this.state.chart_timespan].forEach(function(block, i) {
        
        var d = {
          name: block.key,
          year1: block.totals[this.state.sales_metric],
          year1_label: labels[0]
        };

        if(d.year1 < 0) {
          d.year1 = 0;
        }

        if(this.state.results.timespans.dates[1] && this.state.results.timespans.dates[1].data[this.state.chart_timespan][i].totals[this.state.sales_metric]) {
          d.year2 = this.state.results.timespans.dates[1].data[this.state.chart_timespan][i].totals[this.state.sales_metric];
          d.year2_label = labels[1];
          if(d.year2 < 0) {
            d.year2 = 0;
          }
        }

        if(this.state.results.timespans.dates[2] && this.state.results.timespans.dates[2].data[this.state.chart_timespan][i].totals[this.state.sales_metric]) {
          if(moment(block.key, 'YYYY-MM-DD').isBefore(moment('2022-03-01', 'YYYY-MM-DD'))) {
            d.year3 = this.state.results.timespans.dates[2].data[this.state.chart_timespan][i].totals[this.state.sales_metric];
            d.year3_label = labels[2];
            if(d.year3 < 0) {
              d.year3 = 0;
            }
          }
        }

        if(this.state.results.timespans.dates[3] && this.state.results.timespans.dates[3].data[this.state.chart_timespan][i].totals[this.state.sales_metric]) {
          d.year4 = this.state.results.timespans.dates[3].data[this.state.chart_timespan][i].totals[this.state.sales_metric];
          d.year4_label = labels[3];
          if(d.year4 < 0) {
            d.year4 = 0;
          }
        }

        if(this.state.results.timespans.dates[4] && this.state.results.timespans.dates[4].data[this.state.chart_timespan][i].totals[this.state.sales_metric]) {
          d.year5 = this.state.results.timespans.dates[4].data[this.state.chart_timespan][i].totals[this.state.sales_metric];
          d.year5_label = labels[4];
          if(d.year5 < 0) {
            d.year5 = 0;
          }
        }

        if(this.state.results.timespans.dates[5] && this.state.results.timespans.dates[5].data[this.state.chart_timespan][i].totals[this.state.sales_metric]) {
          d.year6 = this.state.results.timespans.dates[5].data[this.state.chart_timespan][i].totals[this.state.sales_metric];
          d.year6_label = labels[5];
          if(d.year6 < 0) {
            d.year6 = 0;
          }
        }

        chartData.push(d);
       
      }.bind(this));

      var reflines = [];

      var min = 0.9;
      var max = 1.1;

      this.setState({
        chart: {
          data: chartData,
          reflines: reflines,
          properties: {
            max: max,
            min: min
          }
        }
      });
    } else {
      this.setState({
        chart: null
      });
    }
  };

  salesSummaryDataFormat(x) {
    return displayPreSalesMetric(this.state.sales_metric) + nFormatter(x);
  };

  salesSummaryDateFormat(x) {
    if(this.state.chart_timespan === 'day') {
      return moment(x).format('M/DD');
    } else {
      if(this.state.chart_timespan === 'week') {
        return moment(x).format('M/DD');
      } else {
        return moment(x).format('MMM');
      }
    }
  };

  areaLabelFormat(x) {
    return x
  };

  changePerfTrans = () => { 
    this.setState({
      perf_date: !this.state.perf_date
    }, function() {
      this.loadSales();
    });
  };

  changeSalesMetric = () => { 
    var change_display = '';
    if(this.props.affiliate) {
      change_display = changeAffiliateSalesMetric(this.state.sales_metric);
    } else {
      change_display = changeSalesMetric(this.state.sales_metric);
    }
    this.setState({
      sales_metric: change_display
    }, function() {
      this.setChartData();
    });
  };

  changeChartTimespan = () => { 
    var change_display = this.state.chart_timespan;
    if(change_display === 'week') {
      change_display = 'day';
    } else {
      change_display = 'week';
    }
    this.setState({
      chart_timespan: change_display
    }, function() {
      this.setChartData();
    });
  }

  render() {

    var title = <h5><span className="text-danger">Sales Summary</span>&nbsp;&nbsp;<small className="text-muted"><button className="header-link" onClick={() => this.changeSalesMetric()}>{displaySalesMetric(this.state.sales_metric)}</button> by <button className="header-link" onClick={() => this.changePerfTrans()}>{this.state.perf_date ? (<span>Performance Date</span>) : (<span>Transaction Date</span>)}</button>. Chart displays net <button className="header-link" onClick={() => this.changeChartTimespan()}>{this.state.chart_timespan === 'day' ? ('Daily Sales') : ('Weekly Sales')}</button>.</small></h5>;
    if(this.props.print) {
      title = <h5><span className="text-danger">Total Sales</span> <small className="text-muted">with per order averages. "Net" is Service Fees minus Access Fees.</small></h5>;
    }

    return (

      <div>

        <Widget title={title} className={this.props.className}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <div className="gutter-top-fixed-2">
              <Row>
                <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                  <p className="fs-mini text-muted text-center">New Sales</p>
                  <h4 className="text-center">{displayPreSalesMetric(this.state.sales_metric)}<Displays a={parseInt(this.state.results.totals.dates[0].sales[this.state.sales_metric])} format="comma" /></h4>
                  {this.state.sales_metric === 'qty' ? (
                      <p className="fs-mini text-muted text-center">Avg Per: {parseFloat(this.state.results.totals.dates[0].sales.qty/this.state.results.totals.dates[0].sales.orders).toFixed(2)}</p>
                  ) : (
                    this.state.sales_metric === 'face' ? (
                      <p className="fs-mini text-muted text-center">Avg Price: ${parseFloat(this.state.results.totals.dates[0].sales.face/this.state.results.totals.dates[0].sales.qty).toFixed(2)}</p>
                    ) : (
                      this.state.sales_metric === 'fees' ? (
                        <p className="fs-mini text-muted text-center">Avg Margin: {parseFloat((this.state.results.totals.dates[0].sales.fees/this.state.results.totals.dates[0].sales.face) * 100).toFixed(1)}%</p>
                      ) : (
                        this.state.sales_metric === 'affiliate_commission' ? (
                          <p className="fs-mini text-muted text-center">Avg Commission: ${parseFloat(this.state.results.totals.dates[0].sales.affiliate_commission/this.state.results.totals.dates[0].sales.qty).toFixed(2)}</p>
                        ) : (
                          this.state.sales_metric === 'access_fees' ? (
                            <p className="fs-mini text-muted text-center">Avg Fee: ${parseFloat(this.state.results.totals.dates[0].sales.access_fees/this.state.results.totals.dates[0].sales.qty).toFixed(2)}</p>
                          ) : (
                            this.state.sales_metric === 'net_net' ? (
                              <p className="fs-mini text-muted text-center">Avg Margin: {parseFloat((this.state.results.totals.dates[0].sales.net_net/this.state.results.totals.dates[0].sales.face) * 100).toFixed(1)}%</p>
                            ) : (
                              <p className="fs-mini text-muted text-center">--</p>
                            )
                          )
                        )
                      )
                    )
                  )}
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                  <p className="fs-mini text-muted text-center">Refunds</p>
                  <h4 className="text-center">{displayPreSalesMetric(this.state.sales_metric)}<Displays a={parseInt(this.state.results.totals.dates[0].refunds[this.state.sales_metric])} format="comma" /></h4>
                  {this.state.sales_metric === 'qty' ? (
                      <p className="fs-mini text-muted text-center">Avg Per: {parseFloat(this.state.results.totals.dates[0].refunds.qty/this.state.results.totals.dates[0].refunds.orders).toFixed(2)}</p>
                  ) : (
                    this.state.sales_metric === 'face' ? (
                      <p className="fs-mini text-muted text-center">Avg Price: ${parseFloat(this.state.results.totals.dates[0].refunds.face/this.state.results.totals.dates[0].refunds.qty).toFixed(2)}</p>
                    ) : (
                      this.state.sales_metric === 'fees' ? (
                        <p className="fs-mini text-muted text-center">Avg Margin: {parseFloat((this.state.results.totals.dates[0].refunds.fees/this.state.results.totals.dates[0].refunds.face) * 100).toFixed(1)}%</p>
                      ) : (
                        this.state.sales_metric === 'affiliate_commission' ? (
                          <p className="fs-mini text-muted text-center">Avg Commission: ${parseFloat(this.state.results.totals.dates[0].refunds.affiliate_commission/this.state.results.totals.dates[0].refunds.qty).toFixed(2)}</p>
                        ) : (
                          this.state.sales_metric === 'access_fees' ? (
                            <p className="fs-mini text-muted text-center">Avg Fee: ${parseFloat(this.state.results.totals.dates[0].refunds.access_fees/this.state.results.totals.dates[0].refunds.qty).toFixed(2)}</p>
                          ) : (
                            this.state.sales_metric === 'net_net' ? (
                              <p className="fs-mini text-muted text-center">Avg Margin: {parseFloat((this.state.results.totals.dates[0].refunds.net_net/this.state.results.totals.dates[0].refunds.face) * 100).toFixed(1)}%</p>
                            ) : (
                              <p className="fs-mini text-muted text-center">--</p>
                            )
                          )
                        )
                      )
                    )
                  )}
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4} className="gutter-top-fixed-2">
                  <p className="fs-mini text-muted text-center">Net</p>
                  <h4 className="text-center">{displayPreSalesMetric(this.state.sales_metric)}<Displays a={parseInt(this.state.results.totals.dates[0].net[this.state.sales_metric])} format="comma" /></h4>
                  <p className="fs-mini text-muted text-center">--</p>
                </Col>
              </Row>

              {this.state.isLoaded && this.state.chart && this.state.chart.data ? (
                <div className="gutter-top-fixed-3">

                  {this.props.print ? (
                    <div>
                      {this.props.chart && this.state.chart.data.length > 1 ? (
                        <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                          <defs>
                            <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                            </linearGradient>
                            <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                            </linearGradient>
                          </defs>
                          <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                          <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[dataMin => this.state.chart.properties.min, dataMax => (dataMax * this.state.chart.properties.max)]} />
                          {this.state.chart.reflines.length > 0 ? (
                            this.state.chart.reflines.map((refline, i) =>
                              <ReferenceLine key={i} x={refline.x} isFront={true} stroke="lightgray">
                                <Label value={refline.label} offset={refline.offset} position="top" fill="black" style={{fontSize: '0.8rem' }} />
                              </ReferenceLine>
                            )
                          ) : null }

                          <Line name={this.state.chart.data[0].year1_label} type="monotone" dataKey="year1" stroke="#82ca9d" strokeWidth={2} />

                          {this.state.chart.data[0].year2 ? (
                            <Line name={this.state.chart.data[0].year2_label} type="monotone" dataKey="year2" stroke="#ccc" dot={{ stroke: '#ccc', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year3 ? (
                            <Line name={this.state.chart.data[0].year3_label} type="monotone" dataKey="year3" stroke="#0087a9" dot={{ stroke: '#0087a9', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year3 ? (
                            <Line name={this.state.chart.data[0].year4_label} type="monotone" dataKey="year4" stroke="#ffc647" dot={{ stroke: '#ffc647', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year4 ? (
                            <Line name={this.state.chart.data[0].year5_label} type="monotone" dataKey="year5" stroke="#754c7f" dot={{ stroke: '#754c7f', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year5 ? (
                            <Line name={this.state.chart.data[0].year6_label} type="monotone" dataKey="year6" stroke="#db3920" dot={{ stroke: '#db3920', strokeWidth: 1 }} />
                          ) : null }

                          {this.state.chart.data[0].year3 ? (
                            <Legend verticalAlign="top" height={36} align="right" iconType="square" />
                          ) : null }
                        </LineChart>
                        
                      ) : null}
                    </div>
                  ) : (
                    <ResponsiveContainer width='100%' aspect={this.state.chartAspectWidth/this.state.chartAspectHeight} >
                      <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                          <defs>
                            <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                            </linearGradient>
                            <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                            </linearGradient>
                          </defs>
                          <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                          <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[ dataMin => (dataMin * this.state.chart.properties.min), dataMax => (dataMax * this.state.chart.properties.max) ]} />
                          
                          {this.state.chart.reflines.length > 0 ? (
                            this.state.chart.reflines.map((refline, i) =>
                              <ReferenceLine key={i} x={refline.x} isFront={true} stroke="lightgray">
                                <Label value={refline.label} offset={refline.offset} position="top" fill="black" style={{fontSize: '0.8rem' }} />
                              </ReferenceLine>
                            )
                          ) : null }

                          {this.state.chart.data[0].year1 > -1 ? (
                          <Line name={this.state.chart.data[0].year1_label} type="monotone" dataKey="year1" stroke="#82ca9d" strokeWidth={2} />
                          ) : null }
                          {this.state.chart.data[0].year2 > -1 ? (
                            <Line name={this.state.chart.data[0].year2_label} type="monotone" dataKey="year2" stroke="#ccc" dot={{ stroke: '#ccc', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year3 > -1 ? (
                            <Line name={this.state.chart.data[0].year3_label} type="monotone" dataKey="year3" stroke="#0087a9" dot={{ stroke: '#0087a9', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year4 > -1 ? (
                            <Line name={this.state.chart.data[0].year4_label} type="monotone" dataKey="year4" stroke="#ffc647" dot={{ stroke: '#ffc647', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year5 > -1 ? (
                            <Line name={this.state.chart.data[0].year5_label} type="monotone" dataKey="year5" stroke="#754c7f" dot={{ stroke: '#754c7f', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year6 > -1 ? (
                            <Line name={this.state.chart.data[0].year6_label} type="monotone" dataKey="year6" stroke="#db3920" dot={{ stroke: '#db3920', strokeWidth: 1 }} />
                          ) : null }

                          {this.state.chart.data[0].year1 > -1 ? (
                            <Legend verticalAlign="top" height={36} align="right" iconType="square" />
                          ) : null }

                          <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                  )}
                </div>
              ): null}

            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetBwSales));
