import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import { scurl } from '../../../core/utils.js';

import EngagementCard from './cards/Engagement.js';

import { Button } from 'reactstrap';

class WidgetOpsisEngagements extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    engagements: PropTypes.array,
    venue_id: PropTypes.number
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isError: false
    }

    this._isMounted = false;
    this.addTBA = this.addTBA.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  addTBA = () => { 
    var opts = {
      path: '/v2/opsis/venues/' + this.props.venue_id + '/tba',
      type: 'simple'
    };

    scurl(opts, function(err, results) {
      if(err) {
        this.setState({
          error: {
            json: err,
            status: err.status || -99,
            message: err.message || 'Error occured'
          }
        });
      } else {
        window.location.reload(true);
      }
    }.bind(this));
  }

  render() {

    var title = <h5><span className="text-danger">Engagements</span> <small className="text-muted">Forecast settings</small></h5>;
    return (

      <div>

        <Widget title={title}>

          <div>
            
            <div className="gutter-top-fixed-2">

              {this.props.engagements && this.props.engagements.length > 0 ? (
                <div className="mt-1">

                  {this.props.engagements.map((engagement, i) =>
                    engagement.active ? (
                      <div key={i}>
                        <EngagementCard print={this.props.print} engagement={engagement} />
                      </div>
                    ) : null
                  )}
                
                </div>
              ): null}

            </div>

            <hr />

            <div className="text-center">
                      
             <Button className="btn-secondary text-white" onClick={() => this.addTBA()}>
                <i className="fa-light fa-plus" /> ADD TBA
              </Button>
            </div>

          </div>

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetOpsisEngagements));
